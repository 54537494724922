import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import useOnClickOutside from '@sweetspot/sweetspot-js/common/hooks/useOnClickOutside'
import ButtonBasic from '@sweetspot/sweetspot-js/common/components/ButtonBasic'
import DropdownBox from '@sweetspot/sweetspot-js/common/components/FormElements/Partials/DropdownBox'
import {
  SLOT_FILTER_QUERIES as SLOT_FILTER_QUERIES_RAW,
  SLOT_TYPES as SLOT_TYPES_RAW,
} from '@sweetspot/sweetspot-js/features/promotions/constants/actions'
import produce from 'immer'
import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'
import Checkbox from '@sweetspot/sweetspot-js/common/components/FormElements/Checkbox'
import PromotionsContext from '@sweetspot/sweetspot-js/features/promotions/containers/PromotionsManager/PromotionsContext'
import QueryWrapper from '../QueryWrapper'
import MultiSelect from '@sweetspot/sweetspot-js/common/components/MultiSelect'
import useMergeState from '@sweetspot/sweetspot-js/common/hooks/useMergeState'
import NestedRowWrapper from '@sweetspot/sweetspot-js/common/components/NestedRowWrapper'
import { CLUB_QUERIES } from '@sweetspot/shared/util/constants'
import { useInfiniteQuery, useQueries } from 'react-query'
import { queryClubs } from '@sweetspot/sweetspot-js/features/golfClubs/services/api-platform'
import { arrayChunk, to } from '@sweetspot/sweetspot-js/common/functions/utils'
import useCooperations, {
  STATE,
} from '@sweetspot/sweetspot-js/features/cooperations/hooks/useCooperations'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import useMemberships from '@sweetspot/sweetspot-js/features/cooperations/hooks/useMemberships'

let searchDebounce

const formatOptions = (array) => {
  return array.map((club) => ({
    id: club.id,
    label: club.name,
    uuid: club.uuid,
    gitId: club.git_id,
  }))
}

const SlotsFilter = ({ action, onSaveAction, onSetAction, disabled }) => {
  const { t } = useTranslation()
  const { values } = useContext(PromotionsContext)

  const isMounted = useRef(false)

  const [ageErrors, setAgeErrors] = useMergeState({
    from: null,
    to: null,
  })

  const newQueryDropdownRef = useRef()
  const [showNewQueryDropdown, setShowNewQueryDropdown] = useState(false)
  useOnClickOutside(newQueryDropdownRef, () => {
    setShowNewQueryDropdown(false)
  })

  const hasQuery = useMemo(() => {
    const slotFilter = action?.configuration?.filter?.slot_filter
    return (
      slotFilter?.git_home_club_query ||
      slotFilter?.age_query ||
      slotFilter?.slot_type_query ||
      slotFilter?.cooperation_query
    )
  }, [action])

  const SLOT_TYPES = useMemo(() => {
    return SLOT_TYPES_RAW.filter((t) => t.context.includes(values.context))
  }, [values.context])

  const gitHomeClubs = useMemo(() => {
    return action?.configuration?.filter?.slot_filter?.git_home_club_query || []
  }, [action])

  const hasGitHomeClubsQuery = useMemo(() => {
    return action?.configuration?.filter?.slot_filter?.git_home_club_query || false
  }, [action])

  const hasCooperationQuery = useMemo(() => {
    return action?.configuration?.filter?.slot_filter?.cooperation_query || false
  }, [action])

  useEffect(() => {
    isMounted.current = true
    return () => (isMounted.current = false)
  }, [])

  const addNewQuery = (item) => {
    const { value } = item

    let queryValue

    if (value === 'age_query') {
      queryValue = {
        from: null,
        to: null,
        check_by_year: false,
      }
    } else if (value === 'git_home_club_query') {
      queryValue = []
    } else if (value === 'slot_type_query') {
      queryValue = []
    } else if (value === 'cooperation_query') {
      queryValue = []
    }

    const updatedAction = produce(action, (draft) => {
      draft.configuration.filter.slot_filter = {
        ...draft.configuration.filter.slot_filter,
        [value]: queryValue,
      }
    })

    onSetAction(updatedAction)
    onSaveAction(updatedAction)

    setShowNewQueryDropdown(false)
  }

  const removeQuery = (key) => {
    const updatedAction = produce(action, (draft) => {
      delete draft.configuration.filter.slot_filter[key]
    })
    onSetAction(updatedAction)
    onSaveAction(updatedAction)
  }

  const saveToAge = (values, newValue) => {
    const parsed = parseInt(newValue)

    onSetAction(
      produce(action, (draft) => {
        draft.configuration.filter.slot_filter.age_query.to = Number.isInteger(parsed)
          ? parsed
          : null
      })
    )

    setAgeErrors({
      to: null,
    })
  }

  const saveFromAge = (values, newValue) => {
    const parsed = parseInt(newValue)

    onSetAction(
      produce(action, (draft) => {
        draft.configuration.filter.slot_filter.age_query.from = Number.isInteger(parsed)
          ? parsed
          : null
      })
    )

    setAgeErrors({
      from: null,
    })
  }

  const trySave = (type, values) => {
    let hasErrors = false

    setAgeErrors({
      to: null,
      from: null,
    })

    if (type === 'age_query' && (values.to !== null || values.from !== null)) {
      const parsedFrom = parseInt(values.from)
      const parsedFromIsInt = Number.isInteger(parsedFrom)

      const parsedTo = parseInt(values.to)
      const parsedToIsInt = Number.isInteger(parsedTo)

      if (parsedFromIsInt && parsedFrom < 0) {
        setAgeErrors({
          from: t('errors.minNumber', { count: 0 }),
        })
        hasErrors = true
      }

      if (parsedToIsInt && parsedTo < 1) {
        setAgeErrors({
          to: t('errors.minNumber', { count: 1 }),
        })
        hasErrors = true
      }

      // Interrupt checking here if values invalid
      if (hasErrors) return

      if (parsedFromIsInt && parsedToIsInt) {
        if (parsedFrom > parsedTo) {
          setAgeErrors({
            from: t('errors.valueMustBeLowerThanThis', { this: parsedTo }),
          })
          hasErrors = true
        }
        if (parsedTo < parsedFrom) {
          setAgeErrors({
            to: t('errors.valueMustBeHigherThanThis', { this: parsedFrom }),
          })
          hasErrors = true
        }
      }
    }

    if (!hasErrors) {
      onSaveAction()
    }
  }

  const {
    data: clubs,
    fetchNextPage: loadNextClubs,
    isFetching: isFetchingClubs,
    isFetchingNextPage: isFetchingNextPageOfClubs,
    status: clubsStatus,
  } = useInfiniteQuery(
    [CLUB_QUERIES.CLUBS],
    ({ pageParam = 1 }) =>
      queryClubs({
        limit: 50,
        page: pageParam,
        'order[name]': 'asc',
      }),
    {
      enabled: !!hasGitHomeClubsQuery,
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length < 50) return undefined
        return pages?.length + 1
      },
      select: (data) => {
        return formatOptions(data.pages.flat())
      },
    }
  )

  const gitHomeClubsChunks = useMemo(() => {
    return arrayChunk(gitHomeClubs, 100)
  }, [gitHomeClubs])

  const selectedClubsQueries = useQueries(
    gitHomeClubsChunks.map((query) => {
      return {
        queryKey: [CLUB_QUERIES.CLUBS, 'by_uuids', query, { page: 1, limit: 200 }],
        queryFn: () => queryClubs({ 'uuid[]': query, page: 1, limit: 200 }),
        enabled: !!query && !!clubs,
      }
    })
  )

  const selectedClubs = useMemo(() => {
    return formatOptions(selectedClubsQueries.map(({ data }) => (data ? data : [])).flat())
  }, [selectedClubsQueries])

  const searchClubs = async (value) => {
    clearTimeout(searchDebounce)

    return new Promise((resolve) => {
      searchDebounce = setTimeout(async () => {
        const [res] = await to(
          queryClubs({
            'order[name]': 'asc',
            page: 1,
            limit: 200,
            search: value,
          })
        )

        if (!isMounted.current) return

        if (res) {
          resolve(formatOptions(res))
        } else {
          resolve([])
        }
      }, 500)
    })
  }

  const { cooperations, isLoading } = useCooperations(
    hasCooperationQuery ? values.clubId : false,
    [STATE.CONFIRMED, STATE.CANCELED],
    true
  )

  const { memberships: currentMemberships, isFetching: isLoadingMemberships } = useMemberships(
    cooperations.map((item) => item[`${item._isHost ? 'partner' : 'host'}_club`].id)
  )

  // Cooperations are available only for Club context
  const SLOT_FILTER_QUERIES =
    values.context === 'club'
      ? SLOT_FILTER_QUERIES_RAW
      : SLOT_FILTER_QUERIES_RAW.filter(({ id }) => id !== 4)

  return (
    <div className={cx(styles.container)}>
      {!hasQuery && <p className={cx(styles.allSlotsText)}>{t('sentences.allSlotsInBooking')}</p>}

      {hasQuery &&
        Object.keys(action.configuration.filter.slot_filter).map((key) => (
          <NestedRowWrapper
            key={key}
            onRemove={() => removeQuery(key)}
            title={t(SLOT_FILTER_QUERIES.find((x) => x.value === key)?.label)}
          >
            {key === 'age_query' && (
              <QueryWrapper
                action={action}
                filterKey="slot_filter"
                queryKey="age_query"
                render={(value) => (
                  <div className={cx(styles.row)}>
                    <TextInputField
                      containerClassName={cx(styles.input)}
                      labelTwo={t('words.from')}
                      value={value.from === null ? '' : value.from}
                      type="number"
                      onChange={(newValue) => saveFromAge(value, newValue)}
                      disabled={disabled}
                      onEnter={() => trySave('age_query', value)}
                      onInputBlur={() => trySave('age_query', value)}
                      inputProps={{
                        min: 0,
                        max: value.to || 999,
                        step: 1,
                        tabIndex: 1,
                      }}
                      error={ageErrors?.from}
                    />
                    <TextInputField
                      containerClassName={cx(styles.input)}
                      labelTwo={t('words.to')}
                      value={value.to === null ? '' : value.to}
                      type="number"
                      feedbackPosition="top"
                      onChange={(newValue) => saveToAge(value, newValue)}
                      disabled={disabled}
                      onEnter={() => trySave('age_query', value)}
                      onInputBlur={() => trySave('age_query', value)}
                      inputProps={{
                        min: value.from || 1,
                        max: 999,
                        step: 1,
                        tabIndex: 2,
                      }}
                      error={ageErrors?.to}
                    />
                    <Checkbox
                      containerClassName={cx(styles.checkbox)}
                      value={value.check_by_year}
                      onChange={(value) => {
                        const newAction = produce(action, (draft) => {
                          draft.configuration.filter.slot_filter.age_query.check_by_year = value
                        })
                        onSetAction(newAction)
                        onSaveAction(newAction)
                      }}
                      disabled={disabled}
                      lightLabel={true}
                      label={t('sentences.calculateByYear')}
                    />
                  </div>
                )}
              />
            )}
            {key === 'git_home_club_query' && (
              <QueryWrapper
                action={action}
                filterKey="slot_filter"
                queryKey="git_home_club_query"
                render={(queryValue) => (
                  <div className={cx(styles.row)}>
                    <MultiSelect
                      noSelectedLabel={t('sentences.selectGitHomeClub')}
                      width="full"
                      buttonLabel={t('words.club')}
                      options={clubs}
                      selectedOptions={[
                        ...(clubs
                          ? clubs.filter(
                              (club) =>
                                queryValue.includes(club.uuid) &&
                                !selectedClubs.find((x) => x.uuid === club.uuid)
                            )
                          : []),
                        ...selectedClubs,
                      ]}
                      handleChangeSelected={(newValues) => {
                        const updatedAction = produce(action, (draft) => {
                          draft.configuration.filter.slot_filter.git_home_club_query =
                            newValues.map((x) => x.uuid)
                        })
                        onSetAction(updatedAction)
                        onSaveAction(updatedAction)
                      }}
                      dropdownProps={{
                        searchPlaceholder: t('sentences.searchClub'),
                        loading:
                          clubsStatus === 'loading' || isFetchingClubs || isFetchingNextPageOfClubs,
                        loadMorePages: () => loadNextClubs(),
                        onSearch: searchClubs,
                      }}
                      disabled={disabled}
                    />
                  </div>
                )}
              />
            )}
            {key === 'slot_type_query' && (
              <QueryWrapper
                action={action}
                filterKey="slot_filter"
                queryKey="slot_type_query"
                render={(queryValue) => (
                  <div className={cx(styles.row)}>
                    <MultiSelect
                      noSelectedLabel={t('sentences.selectSlotType')}
                      width="full"
                      buttonLabel={t('sentences.slotType')}
                      options={SLOT_TYPES}
                      selectedOptions={SLOT_TYPES.filter((type) =>
                        queryValue.find((x) => x === type.value)
                      )}
                      handleChangeSelected={(newValues) => {
                        const updatedAction = produce(action, (draft) => {
                          draft.configuration.filter.slot_filter.slot_type_query = newValues.map(
                            (x) => x.value
                          )
                        })
                        onSetAction(updatedAction)
                        onSaveAction(updatedAction)
                      }}
                      disabled={disabled}
                      translateLabels={true}
                    />
                  </div>
                )}
              />
            )}
            {key === 'cooperation_query' && values.context === 'club' && (
              <QueryWrapper
                action={action}
                filterKey="slot_filter"
                queryKey="cooperation_query"
                render={(cooperationQueries) => {
                  const queryIDs = cooperationQueries.map((i) => i.cooperation_uuid)
                  const updateQuery = (data) => {
                    const updatedAction = produce(action, (draft) => {
                      draft.configuration.filter.slot_filter.cooperation_query = data
                    })
                    onSetAction(updatedAction)
                    onSaveAction(updatedAction)
                  }
                  const setQueryField = (uuid, key, value) => {
                    updateQuery(
                      cooperationQueries.map((i) => {
                        return i.cooperation_uuid === uuid ? { ...i, [key]: value } : i
                      })
                    )
                  }
                  if (isLoading.fetchCooperations) {
                    return (
                      <PulseLoader
                        showIf={isLoading.fetchCooperations}
                        contentClassName={cx(styles.isLoading)}
                      />
                    )
                  }
                  return (
                    <div className={cx(styles.row)}>
                      <MultiSelect
                        noSelectedLabel={t('cooperationFilter.label_SelectPartnerClub')}
                        buttonLabel={t('cooperationFilter.button_AddClub')}
                        width="full"
                        disabled={disabled}
                        handleChangeSelected={(selectedValues) => {
                          updateQuery(
                            selectedValues.map((i) => {
                              const prevData = cooperationQueries.find(
                                (query) => query.cooperation_uuid === i.uuid
                              )
                              return {
                                cooperation_uuid: i.uuid,
                                all_members: prevData ? prevData.all_members : false,
                                memberships: prevData ? prevData.memberships : [],
                              }
                            })
                          )
                        }}
                        dropdownProps={{
                          searchPlaceholder: t('cooperationFilter.placeholder_SearchClub'),
                          loading: isLoading.fetchCooperations,
                        }}
                        options={cooperations
                          .filter((i) => i.state !== STATE.CANCELED)
                          .map((i) => ({ ...i, label: i.name }))}
                        selectedOptions={cooperations.filter((i) => queryIDs.includes(i.uuid))}
                        selectedClassName={styles.cooperationsList}
                        renderSelected={(cooperation) => {
                          const query = cooperationQueries.find(
                            (i) => i.cooperation_uuid === cooperation.uuid
                          )
                          const target = cooperation._isHost ? 'partner' : 'host'
                          if (isLoading.fetchCooperations) return null

                          const memberships = cooperation[`${target}_club_memberships`].map(
                            (i) => ({
                              ...i,
                              label: i.name,
                            })
                          )

                          if (isLoadingMemberships) {
                            return (
                              <PulseLoader
                                showIf={isLoadingMemberships}
                                key={`loader_${cooperation.uuid}`}
                                contentClassName={cx(styles.isLoading)}
                              />
                            )
                          }
                          const membershipsEmpty = memberships.length === 0
                          const selected = query.memberships.map((uuid) => {
                            const cooperationMembership = cooperation[
                              `${target}_club_memberships`
                            ].find((i) => i.uuid === uuid)
                            if (cooperationMembership) {
                              return {
                                ...cooperationMembership,
                                label: cooperationMembership.name,
                                hoverText: undefined,
                                dotEnabled: false,
                                hoverDirection: undefined,
                              }
                            } else {
                              const clubMembership = currentMemberships.find((i) => i.uuid === uuid)
                              if (clubMembership) {
                                return {
                                  id: clubMembership.id,
                                  label: clubMembership.name,
                                  name: clubMembership.name,
                                  uuid: clubMembership.uuid,
                                  hoverText: t('cooperationFilter.label_MembershipNotIncluded'),
                                  dotEnabled: true,
                                  hoverDirection: 'left',
                                }
                              } else {
                                return {
                                  id: '[removed]',
                                  label: '[removed]',
                                  name: '[removed]',
                                  uuid: '[removed]',
                                  hoverText: t('cooperationFilter.label_MembershipRemoved'),
                                  dotEnabled: true,
                                  hoverDirection: 'left',
                                }
                              }
                            }
                          })
                          return (
                            <NestedRowWrapper
                              key={`coop_${query.cooperation_uuid}`}
                              onRemove={() => {
                                updateQuery(
                                  cooperationQueries.filter(
                                    (i) => i.cooperation_uuid !== query.cooperation_uuid
                                  )
                                )
                              }}
                              title={
                                cooperation.state === STATE.CANCELED
                                  ? `${cooperation.name} (${t(
                                      'cooperationFilter.label_CooperationCanceled'
                                    )})`
                                  : cooperation.name
                              }
                              disabled={cooperation.state === STATE.CANCELED}
                              toggle={{
                                text: t(`cooperationFilter.label_AllMembers`),
                                value: query.all_members,
                                isHidden:
                                  !query.all_members &&
                                  !cooperation[`${target}_club_all_members_option`],
                                onToggle: () =>
                                  setQueryField(
                                    query.cooperation_uuid,
                                    'all_members',
                                    !query.all_members
                                  ),
                                dotEnabled:
                                  query.all_members &&
                                  !cooperation[`${target}_club_all_members_option`],
                                dotText: t('cooperationFilter.label_AllMembersDisabled'),
                              }}
                              hideContent={query.all_members}
                            >
                              <div className={cx(styles.row)}>
                                <MultiSelect
                                  noSelectedLabel={t(
                                    `cooperationFilter.label_${
                                      membershipsEmpty ? 'NoMemberships' : 'SelectMemberships'
                                    }`
                                  )}
                                  buttonLabel={t('cooperationFilter.button_AddMemberships')}
                                  width="full"
                                  disabled={
                                    membershipsEmpty || cooperation.state === STATE.CANCELED
                                  }
                                  dropdownProps={{
                                    searchPlaceholder: t(
                                      'cooperationFilter.placeholder_SearchMembership'
                                    ),
                                    loading: isLoading.fetchCooperations,
                                  }}
                                  options={memberships}
                                  selectedOptions={selected}
                                  handleChangeSelected={(selectedValues) => {
                                    setQueryField(
                                      query.cooperation_uuid,
                                      'memberships',
                                      selectedValues.map((i) => i.uuid)
                                    )
                                  }}
                                />
                              </div>
                            </NestedRowWrapper>
                          )
                        }}
                      />
                    </div>
                  )
                }}
              />
            )}
          </NestedRowWrapper>
        ))}

      <div className={cx(styles.newDropdownContainer)}>
        <ButtonBasic
          icon="plus"
          className={cx(styles.addRow)}
          text={t('words.query')}
          onClick={() => setShowNewQueryDropdown(true)}
          disabled={disabled}
        />
        <DropdownBox
          ref={newQueryDropdownRef}
          dropdownOpen={!disabled && showNewQueryDropdown}
          options={SLOT_FILTER_QUERIES.map((type) => {
            const { value } = type
            if (action?.configuration?.filter?.slot_filter?.[value]) {
              return {
                ...type,
                disabled: true,
              }
            }
            return type
          })}
          className={styles.newDropdown}
          onOptionClick={addNewQuery}
        />
      </div>
    </div>
  )
}

SlotsFilter.propTypes = {
  action: PropTypes.object,
  onSaveAction: PropTypes.func,
  onSetAction: PropTypes.func,
  disabled: PropTypes.bool,
}

SlotsFilter.defaultProps = {
  onSaveAction: () => {},
  onSetAction: () => {},
  disabled: false,
}

export default SlotsFilter
