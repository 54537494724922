import * as React from 'react'
import * as SheetPrimitive from '@radix-ui/react-dialog'
import { tv, type VariantProps } from 'tailwind-variants'
import { cn } from '../../../utils'
import { Button, Divider } from '../../atoms'

const Sheet = SheetPrimitive.Root

const SheetTrigger = SheetPrimitive.Trigger

const SheetClose = SheetPrimitive.Close

const SheetPortal = SheetPrimitive.Portal

const sheetVariants = tv({
  base: 'fixed z-50 gap-4 flex flex-col h-full bg-background shadow-lg transition ease-in-out data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:duration-300 data-[state=open]:duration-500 bg-background-base-clean overflow-y-auto [&::-webkit-scrollbar]:hidden',
  variants: {
    side: {
      top: 'inset-x-0 top-0 border-b data-[state=closed]:slide-out-to-top data-[state=open]:slide-in-from-top',
      bottom:
        'inset-x-0 bottom-0 border-t data-[state=closed]:slide-out-to-bottom data-[state=open]:slide-in-from-bottom',
      left: 'inset-y-0 left-0 h-full w-3/4 border-r data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left sm:max-w-sm',
      right:
        'inset-y-0 right-0 h-full w-3/4  border-l data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right sm:max-w-sm',
    },
  },
  defaultVariants: {
    side: 'right',
  },
})

const SheetOverlay = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <SheetPrimitive.Overlay
    className={cn(
      'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 fixed inset-0 z-50 bg-black/80',
      className
    )}
    {...props}
    ref={ref}
  />
))
SheetOverlay.displayName = SheetPrimitive.Overlay.displayName

interface SheetContentProps
  extends React.ComponentPropsWithoutRef<typeof SheetPrimitive.Content>,
    VariantProps<typeof sheetVariants> {
  disableOverlay?: boolean
  overlayClassName?: string
}

const SheetContent = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Content>,
  SheetContentProps
>(({ side = 'right', className, children, disableOverlay, overlayClassName, ...props }, ref) => (
  <SheetPrimitive.Portal>
    {!disableOverlay && <SheetOverlay className={overlayClassName} />}
    <SheetPrimitive.Content
      ref={ref}
      className={cn(sheetVariants({ side }), className)}
      {...props}
      data-testid="scramble-sheet-content"
    >
      {children}
    </SheetPrimitive.Content>
  </SheetPrimitive.Portal>
))
SheetContent.displayName = SheetPrimitive.Content.displayName

const SheetTitle = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Title>
>(({ className, children, ...props }, ref) => (
  <SheetPrimitive.Title
    ref={ref}
    className={cn('text-text-dark text-content-xl w-full flex-grow font-semibold', className)}
    {...props}
  >
    {children}
  </SheetPrimitive.Title>
))
SheetTitle.displayName = SheetPrimitive.Title.displayName

const SheetHeaderLeftIcon = ({
  className,
  ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement>) => (
  <Button
    variant="clear-dark"
    size="small"
    className={cn(
      'hover:text-text-dark px-md flex-shrink-0 hover:bg-transparent hover:shadow-none',
      className
    )}
    data-testid="scramble-sheet-button-left"
    {...props}
  />
)
SheetHeaderLeftIcon.displayName = 'SheetHeaderLeftIcon'

const SheetHeaderRightIcon = ({
  className,
  ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement>) => (
  <Button
    variant="clear-dark"
    size="small"
    className={cn(
      'hover:text-text-dark px-md flex-shrink-0 hover:bg-transparent hover:shadow-none',
      className
    )}
    data-testid="scramble-sheet-button-right"
    {...props}
  />
)
SheetHeaderRightIcon.displayName = 'SheetHeaderRightIcon'

const SheetHeader = ({ className, children, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn('px-xl pt-xl flex flex-shrink-0 flex-col text-left', className)}
    {...props}
    data-testid="scramble-sheet-header"
  >
    <div className="pb-lg flex items-center gap-x-4">{children}</div>
    <Divider variant="basic" />
  </div>
)
SheetHeader.displayName = 'SheetHeader'

const SheetFooter = ({
  className,
  isSticky,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & { isSticky?: boolean }) => (
  <div
    className={cn('bg-background-base-clean p-lg flex flex-shrink-0 flex-col', className, {
      'sticky bottom-0 shadow-2xl': isSticky,
    })}
    {...props}
  />
)
SheetFooter.displayName = 'SheetFooter'

const SheetCustomContent = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn('px-xl pb-lg flex-grow', className)} {...props} />
)
SheetCustomContent.displayName = 'SheetCustomContent'

export {
  Sheet,
  SheetPortal,
  SheetTrigger,
  SheetClose,
  SheetTitle,
  SheetOverlay,
  SheetContent,
  SheetHeader,
  SheetHeaderLeftIcon,
  SheetHeaderRightIcon,
  SheetFooter,
  SheetCustomContent,
}
