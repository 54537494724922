import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'
import MiniCalendar from '@sweetspot/sweetspot-js/common/components/MiniCalendar'
import moment from 'moment'
import DateHelpers from '@sweetspot/sweetspot-js/common/functions/DateHelpers'
import { capitalize } from '@sweetspot/sweetspot-js/common/functions/utils'
import FeedbackContainer from '@sweetspot/sweetspot-js/common/components/FormElements/Partials/FeedbackContainer'
import { faCalendar } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@sweetspot/scramble-ds'
import CalendarBox from '@sweetspot/sweetspot-js/common/components/CalendarBox'

const InputSelectDateSimple = ({
  currentDate,
  onSetDate,
  disabled,
  className,
  disableBeforeToday,
  value,
  placeholder,
  orientation,
  startDate,
  endDate,
  label,
  showDisabled,
  error,
  newDesign,
  inputClassName,
  color,
  helperText,
  calendarMobileResponsive,
}) => {
  const [focused, setFocused] = React.useState(false)
  const calendarContainerRef = React.useRef()

  const getStartDate = () => {
    if (startDate) {
      return moment(startDate).toDate()
    }
    return moment(currentDate).subtract(2, 'years').toDate()
  }

  const getEndDate = () => {
    if (endDate) {
      return moment(endDate).toDate()
    }
    return moment(currentDate).add(2, 'years').toDate()
  }

  const onFocusHandler = () => {
    if (!disabled) {
      setFocused(true)
    }
  }

  const onSetDateHandler = (date) => {
    onSetDate(date)
    setFocused(false)
  }

  return (
    <div className={cx(styles.container, className)} ref={calendarContainerRef}>
      {label && <label className="ss-label">{label}</label>}
      {!newDesign && (
        <div
          className={cx('ss-input-row', { disabled, focused, error: !!error })}
          style={{ width: '100%' }}
          onClick={onFocusHandler}
        >
          <div className={cx('ss-prefix-suffix prefix inline')}>
            <span className={'material-icons md-12 md-light'}>event_note</span>
          </div>
          <input
            className={cx('ss-input has-prefix prefix-inline', newDesign && styles.newInput)}
            readOnly
            value={value}
            placeholder={placeholder}
          />
        </div>
      )}
      {newDesign && (
        <div className={cx(styles.inputContainer, inputClassName)}>
          <input
            className={cx('ss-input has-prefix prefix-inline', newDesign && styles.newInput)}
            hidden={newDesign}
            readOnly
            value={value}
            placeholder={placeholder}
          />
          <Button
            onClick={onFocusHandler}
            size="small"
            variant={color === 'light' ? 'clear-light' : 'clear-dark'}
            className={`
              ${
                color === 'light' ? 'hover:!text-text-light' : 'hover:!text-text-dark'
              } w-full gap-x-3 bg-transparent px-0 !text-base outline-0 hover:bg-transparent hover:shadow-none
            `}
          >
            <FontAwesomeIcon icon={faCalendar} />
            {value ? value : placeholder}
          </Button>
        </div>
      )}
      {focused && (
        <CalendarBox
          mobileResponsive={calendarMobileResponsive}
          visible={true}
          containerRef={calendarContainerRef}
          onClosePreview={() => setFocused(false)}
          helperText={helperText}
          CalendarComponent={{
            component: MiniCalendar,
            props: {
              isOpen: true,
              selectedDate: moment(currentDate).toDate(),
              startDate: getStartDate(),
              endDate: getEndDate(),
              availableDays: DateHelpers.getDaysArray().map((day) => capitalize(day)),
              onDateChange: (date) => onSetDateHandler(date),
              disableBeforeToday: disableBeforeToday,
              showDisabled: showDisabled,
              showBottomBorder: !!helperText,
            },
          }}
        />
      )}
      {!focused && <FeedbackContainer error={error} />}
    </div>
  )
}

InputSelectDateSimple.propTypes = {
  options: PropTypes.array,
  onOptionSelect: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  currentDate: PropTypes.instanceOf(moment),
  onSetDate: PropTypes.func,
  disableBeforeToday: PropTypes.bool,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  orientation: PropTypes.oneOf(['left', 'right', 'top', 'bottom', 'bottomRight']),
  startDate: PropTypes.instanceOf(moment),
  endDate: PropTypes.instanceOf(moment),
  showDisabled: PropTypes.bool,
  newDesign: PropTypes.bool,
  inputClassName: PropTypes.string,
  color: PropTypes.string,

  /** Error message to show for this field */
  error: PropTypes.string,
}

InputSelectDateSimple.defaultProps = {
  options: [],
  onOptionSelect: () => {},
  className: '',
  disabled: false,
  disableBeforeToday: true,
  value: '',
  placeholder: '',
  orientation: 'bottom',
  startDate: null,
  endDate: null,
  error: '',
  newDesign: false,
  inputClassName: '',
  label: '',
  color: '',
}

export default InputSelectDateSimple
