import { CreateBayPayload, UpdateBayPayload } from '@sweetspot/shared/data-access/api-platform'
import { Range, BayTypeV2, BayType, BayScreenDisplay, BayScreenMode } from '@sweetspot/shared/types'

export enum BaySideBarMode {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  VIEW = 'VIEW',
}

export type BaysContextValue = {
  areRangesLoading: boolean
  selectedRange?: Range
  setSelectedRange?: (range: Range) => void
  ranges: Range[]
  refetchRanges: () => void
  bays: BayTypeV2[]
  areBaysLoading: boolean
  areBaysFetching: boolean
  selectedBays?: BayTypeV2[]
  setSelectedBays: (bays: BayTypeV2[]) => void
  openBaySideBar: boolean
  toggleBaySideBar: (mode?: BaySideBarMode) => void
  changeBaySideBarMode: (mode: BaySideBarMode) => void
  reFetchBays: () => void
  baySideBarMode?: BaySideBarMode
  openDiscardDialog: boolean
  setOpenDiscardDialog: (open: boolean) => void
  updateBay?: (bay: UpdateBayPayload & { siteId?: string }) => Promise<void[]>
  createBay?: (bay: CreateBayPayload & { siteId?: string }) => Promise<void[]>
  checkForErrors?: (payload: (CreateBayPayload | UpdateBayPayload) & { siteId?: string }) => string
}

export type BayTableRowData = Omit<BayType, 'drivingRangeUuid'> & {
  tracking_technology_display?: BayScreenDisplay
  tracking_technology_screen_mode?: BayScreenMode
}
