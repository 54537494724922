import React, { useMemo, useState, useEffect, useCallback } from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'

import { useSelector } from 'react-redux'
import { CLUB_QUERIES, QUERY_KEYS } from '@sweetspot/shared/util/constants'
import { getSpaces } from '@sweetspot/sweetspot-js/features/spaces/services/api-platform'
import {
  getQTApiConfig,
  getQTObjects,
  getQTVersion,
  setQTApiKey,
  setQTConfig as saveQTConfig,
} from '@sweetspot/sweetspot-js/features/qtIntegration/services/api-platform'
import { useQuery } from 'react-query'
import { to } from '@sweetspot/sweetspot-js/common/functions/utils'

import TokenVerification from './components/TokenVerification'
import SpacesAndObjectsTable from './components/SpacesAndObjectsTable'
import TextInputOptionsSelect from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputOptionsSelect'
import COURSE_TYPES from '@sweetspot/sweetspot-js/features/courses/constants/courseTypes'
import { useToasts } from 'react-toast-notifications'
import { queryRanges } from '@sweetspot/sweetspot-js/features/ranges/services'
import { queryBays } from '@sweetspot/sweetspot-js/features/bays/services'

const QTIntegration = () => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const golfCoursesRaw = useSelector((state) => state?.golfCourse?.list)
  const golfClub = useSelector((state) =>
    state?.golfClub?.list?.find((club) => club.id === state?.golfClub?.selectedId)
  )
  const [currentGolfCourse, setCurrentGolfCourse] = useState(undefined)

  const [apiToken, setApiToken] = useState('')
  const [QTConfig, setQTConfig] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState(null)

  const formatedCourses = useMemo(() => {
    if (!golfCoursesRaw) return []
    return golfCoursesRaw
      .filter((course) =>
        [COURSE_TYPES.DRIVING_RANGE.value, COURSE_TYPES.SIMULATOR.value].includes(course.type)
      )
      .map((course) => ({
        name: course.name,
        label: course.name,
        id: course.id,
        uuid: course.uuid,
        type: course.type,
        belongs_to_range_context: course.belongs_to_range_context,
      }))
  }, [golfCoursesRaw])

  useEffect(() => {
    if (currentGolfCourse?.id) {
      resetState()
      getClubsQTConfig(currentGolfCourse.id)
    }
  }, [currentGolfCourse])

  const golfCourses = useMemo(() => {
    return golfCoursesRaw.map((course) => ({ ...course, label: course.name }))
  }, [golfCoursesRaw])

  const { data: bayRanges, isFetched: isBayRangesFetched } = useQuery(
    [QUERY_KEYS.RANGES, golfClub?.id, currentGolfCourse?.id],
    async () => {
      return queryRanges({ organizationId: golfClub.uuid })
    },
    {
      enabled: !!golfClub?.uuid,
      select: (data) => {
        return data?.ranges
      },
    }
  )

  const isNewRange = useMemo(() => {
    return (
      currentGolfCourse?.belongs_to_range_context &&
      bayRanges?.find((r) => r?.external_reference === currentGolfCourse?.uuid)
    )
  }, [currentGolfCourse?.belongs_to_range_context, bayRanges, currentGolfCourse?.uuid])

  const { data: spaces, isFetching } = useQuery(
    [isNewRange ? CLUB_QUERIES.BAYS : CLUB_QUERIES.SPACES, { course: currentGolfCourse?.id }],
    () =>
      isNewRange
        ? queryBays({ drivingRangeId: currentGolfCourse?.rangeId })
        : getSpaces({ course: currentGolfCourse?.id }),
    {
      enabled: !!currentGolfCourse?.id && !!isBayRangesFetched,
      select: (data) => {
        let res = data
        if (isNewRange) {
          res = data?.bays
        }
        return res.map((item) => {
          if (isNewRange) {
            item.name = `${t('words.bay')} ${item.bay_number}`
            item.uuid = item.id
          }
          return { ...item, label: item.name }
        })
      },
    }
  )

  const resetApiToken = () => {
    setApiToken('')
  }

  const resetState = () => {
    setQTConfig(null)
    setIsLoading(false)
    setErrors(null)
  }

  const getClubsQTConfig = async (courseId) => {
    setIsLoading(true)
    const [configRes] = await to(getQTApiConfig(courseId))
    const [versionRes] = await to(getQTVersion(courseId))
    const [objectsRes] = await to(getQTObjects(courseId))

    if (configRes && versionRes && Array.isArray(objectsRes)) {
      const config = {
        ...configRes,
        ...versionRes,
        objects: objectsRes,
        mappedObjects: configRes?.objects,
      }
      setQTConfig(config)
      setApiToken(config?.api_key)
    } else {
      if (apiToken.length) setErrors(t('errors.apiTokenError'))
    }
    setIsLoading(false)
  }

  const updateQTApiToken = async () => {
    setErrors(null)
    setIsLoading(true)
    const [res, err] = await to(setQTApiKey(currentGolfCourse.id, { api_key: apiToken }))

    if (res) {
      getClubsQTConfig(currentGolfCourse.id)
    } else if (err) {
      resetApiToken()
      resetState()
      setErrors(t('errors.apiTokenError'))
    }
  }

  const saveQTObjectsMap = async (data) => {
    setIsLoading(true)
    const [res, err] = await to(saveQTConfig(currentGolfCourse.id, data))

    if (res) {
      getClubsQTConfig(currentGolfCourse.id)
      addToast(t('sentences.settingsSaved'), { appearance: 'success' })
    } else if (err) {
      resetState()
      setErrors(t('errors.apiTokenError'))
    }
  }

  const handleGolfCourseSelect = useCallback(
    (option) => {
      if (option?.belongs_to_range_context) {
        const bayRange = bayRanges?.find((r) => r?.external_reference === option?.uuid)
        option.rangeId = bayRange?.id
      }
      setCurrentGolfCourse(option)
    },
    [bayRanges]
  )

  return (
    <div className={cx(styles.container)}>
      <h2 className={cx(styles.title)}>{t('sentences.qtIntegration')}</h2>

      <TextInputOptionsSelect
        label={t('words.course')}
        options={formatedCourses}
        value={currentGolfCourse?.name || ''}
        placeholder={t('sentences.selectCourse')}
        onOptionSelect={handleGolfCourseSelect}
        inputReadOnly
        containerWidth="286px"
      />

      <TokenVerification
        apiToken={apiToken}
        errors={errors}
        updateQTApiToken={updateQTApiToken}
        setApiToken={setApiToken}
        isLoading={isLoading || isFetching}
        QTConfig={QTConfig}
        disabled={!currentGolfCourse?.id || isLoading || isFetching}
      />

      {QTConfig ? (
        <SpacesAndObjectsTable
          spaces={spaces}
          courses={golfCourses}
          isLoading={isLoading}
          config={QTConfig}
          saveObjects={(mappedData) => {
            saveQTObjectsMap(mappedData)
          }}
        />
      ) : null}
    </div>
  )
}

QTIntegration.propTypes = {}

QTIntegration.defaultProps = {}

export default QTIntegration
