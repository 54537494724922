import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Check,
  Divider,
  Input,
  InputBase,
  InputContainer,
  InputLabelContainer,
  InputLeadingContainer,
  InputSelect,
  InputSelectContent,
  InputSelectContentView,
  InputSelectItem,
  InputSelectStatefulInput,
  InputSelectTrailingContainer,
  InputSelectTrigger,
  ListItem,
  ListItemMainContent,
  ListItemParagraph,
} from '@sweetspot/scramble-ds'
import { capitalize } from '@sweetspot/sweetspot-js/common/functions/utils'
import { BayScreenDisplay, BayScreenMode, TrackingTech, Stance } from '@sweetspot/shared/types'

import { trackingTechnologies } from '../../constants'
import PopoverHint from './PopoverHint'

type Props = {
  floor?: number
  setFloor?: (value: number) => void
  stance?: Stance
  setStance?: (value: Stance) => void
  roof: boolean
  setRoof?: (value: boolean) => void
  trackingTech: string
  setTrackingTech?: (value: TrackingTech) => void
  display?: BayScreenDisplay
  setDisplay?: (value: BayScreenDisplay) => void
  screenMode?: BayScreenMode
  setScreenMode?: (value: BayScreenMode) => void
  siteId?: string
  setSiteId?: (value: string) => void
  bayNumber?: number
  trmsNumber?: string
  setTrmsNumber?: (value: string) => void
}

const DetailsForm = ({
  floor,
  setFloor,
  stance,
  setStance,
  roof,
  setRoof,
  trackingTech,
  setTrackingTech,
  display,
  setDisplay,
  screenMode,
  setScreenMode,
  siteId,
  setSiteId,
  bayNumber,
  trmsNumber,
  setTrmsNumber,
}: Props) => {
  const { t } = useTranslation()

  const displays = useMemo(
    () => [
      {
        value: BayScreenDisplay.TOPTRACER_SCREEN,
        label: t('settings.bays.toptracerScreen'),
      },
      {
        value: BayScreenDisplay.PLAYER_DEVICE,
        label: t('settings.bays.playerDevice'),
      },
    ],
    [t]
  )

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex flex-row gap-4">
        <InputBase>
          <InputLabelContainer>{t('words.floor')}</InputLabelContainer>
          <InputContainer>
            <Input
              value={floor}
              onChange={(event) => setFloor && setFloor(+event.target.value)}
              type="number"
              className="pr-md border-border-stroke-clean h-8 w-full border-[1px] pl-2"
              placeholder={t('settings.bays.floorPlaceholder')}
            />
          </InputContainer>
        </InputBase>
        <InputBase>
          <InputLabelContainer>{t('settings.bays.stance')}</InputLabelContainer>
          <InputSelect value={stance} onValueChange={setStance}>
            <InputSelectTrigger asChild>
              <InputContainer>
                <InputSelectStatefulInput
                  className="text-content-base h-8 border-[1px] py-1"
                  value={stance && t(`settings.bays.${stance}`)}
                  placeholder={t('words.select')}
                />
                <InputSelectTrailingContainer className="right-2" />
              </InputContainer>
            </InputSelectTrigger>
            <InputSelectContent>
              <InputSelectContentView>
                {Object.values(Stance)?.map((option) => (
                  <InputSelectItem key={option} value={option}>
                    <ListItem className="ml-2 h-full justify-center gap-2">
                      <ListItemMainContent className="justify-center">
                        <ListItemParagraph className="text-content-base">
                          {t(`settings.bays.${option}`)}
                        </ListItemParagraph>
                      </ListItemMainContent>
                    </ListItem>
                  </InputSelectItem>
                ))}
              </InputSelectContentView>
            </InputSelectContent>
          </InputSelect>
        </InputBase>
      </div>
      <div className="bg-background-base-neutral w-full rounded-md px-4 py-3">
        <div className="text-content-sm font-bold">{t('words.amenities')}</div>
        <Check
          onCheckedChange={setRoof}
          id={'roof'}
          label={t('settings.bays.roof')}
          checked={roof}
          className="h-auto px-0 pt-2"
        />
      </div>

      <Divider />

      <div className="bg-background-base-neutral flex w-full flex-col gap-4 rounded-md px-2 py-3">
        <div className="text-text-dark text-content-base ml-2 font-bold">
          {t('settings.bays.ballTrackingTechnology')}
        </div>
        <InputSelect onValueChange={setTrackingTech} value={trackingTech}>
          <InputBase>
            <InputLabelContainer>{t('settings.bays.trackingProvider')}</InputLabelContainer>
            <InputSelectTrigger asChild>
              <InputContainer>
                <InputSelectStatefulInput
                  className="text-content-base h-8 border-[1px]"
                  value={capitalize(trackingTech)}
                />
                <InputSelectTrailingContainer />
              </InputContainer>
            </InputSelectTrigger>
          </InputBase>

          <InputSelectContent>
            <InputSelectContentView>
              {trackingTechnologies.map((trackingTech) => (
                <InputSelectItem key={trackingTech} value={trackingTech}>
                  <ListItem className="ml-2 h-full justify-center gap-2">
                    <ListItemMainContent className="justify-center">
                      <ListItemParagraph className="text-content-base">
                        {capitalize(trackingTech)}
                      </ListItemParagraph>
                    </ListItemMainContent>
                  </ListItem>
                </InputSelectItem>
              ))}
            </InputSelectContentView>
          </InputSelectContent>
        </InputSelect>

        {trackingTech === TrackingTech.TOPTRACER && (
          <>
            <InputSelect onValueChange={setDisplay} value={display}>
              <InputBase>
                <InputLabelContainer>{t('settings.bays.display')}</InputLabelContainer>
                <div className="flex w-full gap-2">
                  <InputSelectTrigger asChild>
                    <InputContainer>
                      <InputSelectStatefulInput
                        className="text-content-base h-8 border-[1px]"
                        value={displays.find(({ value }) => value === display)?.label}
                        placeholder={t('words.select')}
                      />
                      <InputSelectTrailingContainer />
                    </InputContainer>
                  </InputSelectTrigger>
                  <PopoverHint i18nKey={'settings.bays.displayHint'} />
                </div>
              </InputBase>

              <InputSelectContent>
                <InputSelectContentView>
                  {displays.map(({ value, label }) => (
                    <InputSelectItem key={value} value={value}>
                      <ListItem className="ml-2 h-full justify-center gap-2">
                        <ListItemMainContent className="justify-center">
                          <ListItemParagraph className="text-content-base">
                            {label}
                          </ListItemParagraph>
                        </ListItemMainContent>
                      </ListItem>
                    </InputSelectItem>
                  ))}
                </InputSelectContentView>
              </InputSelectContent>
            </InputSelect>

            {display === BayScreenDisplay.TOPTRACER_SCREEN && (
              <>
                <InputSelect onValueChange={setScreenMode} value={screenMode}>
                  <InputBase>
                    <InputLabelContainer>{t('settings.bays.screenMode')}</InputLabelContainer>
                    <div className="flex w-full gap-2">
                      <InputSelectTrigger asChild>
                        <InputContainer>
                          <InputSelectStatefulInput
                            className="text-content-base h-8 border-[1px]"
                            value={
                              screenMode === BayScreenMode.OPEN
                                ? t('words.unlocked')
                                : screenMode === BayScreenMode.LOCKED
                                ? t('words.locked')
                                : ''
                            }
                            placeholder={t('words.select')}
                          />
                          <InputSelectTrailingContainer />
                        </InputContainer>
                      </InputSelectTrigger>
                      <PopoverHint i18nKey={'settings.bays.screenModeHint'} />
                    </div>
                  </InputBase>

                  <InputSelectContent>
                    <InputSelectContentView>
                      {Object.values(BayScreenMode).map((mode) => (
                        <InputSelectItem key={mode} value={mode}>
                          <ListItem className="ml-2 h-full justify-center gap-2">
                            <ListItemMainContent className="justify-center">
                              <ListItemParagraph className="text-content-base">
                                {mode === BayScreenMode.OPEN
                                  ? t('words.unlocked')
                                  : t('words.locked')}
                              </ListItemParagraph>
                            </ListItemMainContent>
                          </ListItem>
                        </InputSelectItem>
                      ))}
                    </InputSelectContentView>
                  </InputSelectContent>
                </InputSelect>
                <div className="flex flex-col gap-2">
                  <div className="text-text-dark text-content-sm ml-2 font-bold">
                    {t('settings.bays.apiDetails')}
                  </div>

                  <InputBase>
                    <InputLabelContainer className="mb-0.5">
                      {t('settings.bays.siteId')}
                    </InputLabelContainer>
                    <div className="flex w-full gap-2">
                      <InputContainer>
                        <InputLeadingContainer className="left-2">
                          <i className="fa-regular fa-link" />
                        </InputLeadingContainer>
                        <Input
                          value={siteId}
                          onChange={(event) => setSiteId?.(event.target.value)}
                          className="text-content-base h-8 border pl-10"
                          placeholder={t('settings.bays.siteIdPlaceholder')}
                        />
                      </InputContainer>
                      <PopoverHint i18nKey={'settings.bays.siteIdHint'} />
                    </div>
                  </InputBase>
                </div>

                <Divider />

                <InputBase>
                  <InputLabelContainer className="mb-0.5">
                    {`${t('settings.bays.bay')} ${bayNumber} - ${t('settings.bays.trmsBayNumber')}`}
                  </InputLabelContainer>
                  <div className="flex w-full gap-2">
                    <InputContainer>
                      <InputLeadingContainer className="left-2">
                        <i className="fa-regular fa-link" />
                      </InputLeadingContainer>
                      <Input
                        value={trmsNumber}
                        onChange={(event) => setTrmsNumber?.(event.target.value)}
                        className="text-content-base h-8 border pl-10"
                        placeholder={t('sentences.typeValue')}
                      />
                    </InputContainer>
                    <PopoverHint i18nKey={'settings.bays.trmsBayNumberHint'} />
                  </div>
                </InputBase>
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default DetailsForm
