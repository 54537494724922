import { useState, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Link, useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { ACCESS_KEYS } from '@sweetspot/sweetspot-js/features/userAccess/constants/accessTable'
import { getFirstMatchingRole } from '@sweetspot/sweetspot-js/features/userAccess/utils/utils'
import { queryClient } from '../Root/react-query-wrapper'
import { PAYMENT_QUERY_KEYS } from '@sweetspot/club-portal/feature/payment'
import { getFailedMembershipPayments } from '@sweetspot/shared/data-access/api-platform'
import COURSE_TYPES from '@sweetspot/sweetspot-js/features/courses/constants/courseTypes'
import { track } from '@amplitude/analytics-browser'
import { AMPLITUDE_EVENTS } from '@sweetspot/shared/util/constants'
import {
  BottomNavContent,
  SecondLevelItem,
  SideSystemNav,
  ThirdLevelItem,
  TopLevelItem,
} from '@sweetspot/scramble-ds'
import { logout } from '@sweetspot/club-portal-legacy/store/actions'
import { delDataPersistent } from '@sweetspot/club-portal-legacy/store/storage'
import { hasAccess } from '@sweetspot/sweetspot-js/features/userAccess/utils/utils'
import { FlagNames, useFlag } from '@sweetspot/shared/util/feature-flag'
import { useQuery } from 'react-query'

const sessionList = ['membership.memberships', 'playersText']

const PatchedLink = ({ to, children, externalLink, disabled, ...props }) => {
  const match = useRouteMatch({ path: to, exact: true })

  if (disabled) return children({ isActive: false })

  if (externalLink) {
    return (
      <a href={to} target="_blank" rel="noreferrer">
        {children({ isActive: false })}
      </a>
    )
  }

  return (
    <Link to={to} {...props}>
      {children({ isActive: !!match })}
    </Link>
  )
}

const WrappedTopLevelItem = ({
  text,
  to = undefined,
  activeIconFaClassName,
  inactiveIconFaClassName,
  onClick = undefined,
  externalLink = false,
  children,
  disabled = false,
}) => {
  if (to)
    return (
      <PatchedLink to={to} externalLink={externalLink} disabled={disabled}>
        {({ isActive }) => (
          <TopLevelItem
            isActive={isActive}
            text={text}
            activeIconFaClassName={activeIconFaClassName}
            inactiveIconFaClassName={inactiveIconFaClassName}
            onClick={onClick}
            children={children}
            disabled={disabled}
          />
        )}
      </PatchedLink>
    )

  return (
    <TopLevelItem
      text={text}
      activeIconFaClassName={activeIconFaClassName}
      inactiveIconFaClassName={inactiveIconFaClassName}
      onClick={onClick}
      children={children}
      disabled={disabled}
    />
  )
}

const WrappedSecondLevelItem = ({
  text,
  to = undefined,
  onClick = undefined,
  children,
  externalLink = false,
  disabled = false,
  showAlertBadge = false,
}) => {
  if (to)
    return (
      <PatchedLink to={to} externalLink={externalLink} disabled={disabled}>
        {({ isActive }) => (
          <SecondLevelItem
            isActive={isActive}
            text={text}
            onClick={onClick}
            children={children}
            showAlertBadge={showAlertBadge}
            disabled={disabled}
          />
        )}
      </PatchedLink>
    )

  return <SecondLevelItem text={text} onClick={onClick} children={children} disabled={disabled} />
}

const WrappedThirdLevelItem = ({
  text,
  to = undefined,
  onClick = undefined,
  children,
  externalLink,
  disabled = false,
}) => {
  if (to)
    return (
      <PatchedLink to={to} externalLink={externalLink} disabled={disabled}>
        {({ isActive }) => (
          <ThirdLevelItem
            isActive={isActive}
            text={text}
            onClick={onClick}
            children={children}
            disabled={disabled}
          />
        )}
      </PatchedLink>
    )

  return <ThirdLevelItem text={text} onClick={onClick} children={children} disabled={disabled} />
}

/**
 * Checks if there are maximum failed attempts for payment.
 * @returns {boolean} Returns true if there are maximum failed attempts, otherwise false.
 */
const checkForMaxFailedAttempts = (failedAttempts) => {
  if (failedAttempts?.length === 0 || !failedAttempts) return false

  return failedAttempts?.some(
    (failedAttempt) => failedAttempt?.retry?.attempts === failedAttempt?.retry?.max_attempts
  )
}

const getClubNavItems = (options, handleLogout, t) => {
  return {
    topNav: [
      {
        text: t('sideMenu.menuItems.calendars'),
        activeIconFaClassName: 'fa-solid fa-calendars',
        inactiveIconFaClassName: 'fa-light fa-calendars',
        onClick: () => track(AMPLITUDE_EVENTS.CALENDARS_TAPPED),
        subItems: [
          ...(options?.isCoursesAvailable
            ? [
                {
                  text: t('menuItems.courseTeeSheet'),
                  to: '/course-venue',
                  accessKey: ACCESS_KEYS.PAGES.TEE_SHEET.READ,
                },
              ]
            : []),
          {
            text: t('menuItems.simulatorsSheet'),
            to: '/simulators-venue',
            accessKey: ACCESS_KEYS.PAGES.TEE_SHEET.READ,
          },
          {
            text: t('menuItems.rangesSheet'),
            to: '/ranges-venue',
            accessKey: ACCESS_KEYS.PAGES.TEE_SHEET.READ,
          },
          {
            text: t('words.pro'),
            to: '/pro-venue',
            accessKey: ACCESS_KEYS.PAGES.TEE_SHEET.READ,
          },
          {
            text: t('sideMenu.menuItems.miscellaneous'),
            to: '/other-venue',
            accessKey: ACCESS_KEYS.PAGES.TEE_SHEET.READ,
          },
        ],
      },
      {
        text: t('sideMenu.menuItems.rentals'),
        inactiveIconFaClassName: 'fa-kit fa-golf-cart-light',
        activeIconFaClassName: 'fa-kit fa-golf-cart-solid',
        accessKey: ACCESS_KEYS.SETTINGS.GOLF_CARTS,
        to: '/settings/golf-carts',
        onClick: () => track(AMPLITUDE_EVENTS.RENTALS_TAPPED),
      },
      {
        text: t('sideMenu.menuItems.bookings.text'),
        inactiveIconFaClassName: 'fa-light fa-calendar-check',
        activeIconFaClassName: 'fa-solid fa-calendar-check',
        onClick: () => track(AMPLITUDE_EVENTS.BOOKINGS_TAPPED),
        accessKey: ACCESS_KEYS.PAGES.BOOKINGS,
        subItems: [
          {
            text: t('words.overview'),
            to: '/bookings',
            accessKey: ACCESS_KEYS.PAGES.BOOKINGS,
          },
          {
            text: t('noShow'),
            to: '/no-show',
            accessKey: ACCESS_KEYS.PAGES.NO_SHOW,
            onClick: () => track(AMPLITUDE_EVENTS.NO_SHOW_TAPPED),
          },
        ],
      },
      {
        text: t('sideMenu.menuItems.players.text'),
        inactiveIconFaClassName: 'fa-light fa-users',
        activeIconFaClassName: 'fa-solid fa-users',
        to: '/players',
        accessKey: ACCESS_KEYS.PAGES.PLAYERS.READ,
        onClick: () => track(AMPLITUDE_EVENTS.PLAYERS_TAPPED),
      },
      {
        text: t('sideMenu.menuItems.membership.text'),
        inactiveIconFaClassName: 'fa-light fa-badge-check',
        activeIconFaClassName: 'fa-solid fa-badge-check',
        onClick: () => track(AMPLITUDE_EVENTS.MEMBERSHIPS_TAPPED),
        subItems: [
          {
            text: t('menuItems.memberships.overview'),
            to: '/memberships',
            accessKey: ACCESS_KEYS.PAGES.MEMBERSHIPS.READ,
          },
          {
            text: t('menuItems.memberships.failedPayments'),
            to: '/memberships/failed-payments',
            accessKey: ACCESS_KEYS.PAGES.MEMBERSHIPS.FAILED_PAYMENTS.READ,
            showNewContentIcon:
              queryClient.getQueryData([PAYMENT_QUERY_KEYS.FAILED_PAYMENT, 1, []])?.['hydra:member']
                ?.length > 0,
            showAlertBadge: options?.isMaxFailedAttempts,
          },
          {
            text: t('menuItems.memberships.importInvites'),
            to: '/memberships/import-members/start-membership-import',
            accessKey: ACCESS_KEYS.PAGES.MEMBERSHIPS.IMPORT_INVITES,
          },
          {
            text: t('menuItems.memberships.pendingInvites'),
            to: '/memberships/import-members/pending-membership-invites',
            accessKey: ACCESS_KEYS.PAGES.MEMBERSHIPS.PENDING_INVITES,
          },
          {
            text: t('menuItems.memberships.membershipSignups'),
            to: '/memberships/membership-signups',
            accessKey: ACCESS_KEYS.PAGES.MEMBERSHIPS.MEMBERSHIP_SIGNUPS,
          },
          {
            text: t('settings.playRights.title'),
            to: '/settings/play-rights',
            accessKey: ACCESS_KEYS.SETTINGS.PLAY_RIGHTS,
          },
        ],
      },
      {
        text: t('sideMenu.menuItems.payments'),
        inactiveIconFaClassName: 'fa-light fa-money-bill-transfer',
        activeIconFaClassName: 'fa-solid fa-money-bill-transfer',
        accessKey: ACCESS_KEYS.PAGES.PAYMENTS,
        to: '/payments',
        onClick: () => track(AMPLITUDE_EVENTS.PAYMENTS_TAPPED),
      },
      {
        text: t('communication.title'),
        inactiveIconFaClassName: 'fa-light fa-envelope',
        activeIconFaClassName: 'fa-solid fa-envelope',
        onClick: () => track(AMPLITUDE_EVENTS.COMMUNICATION_TAPPED),
        subItems: [
          {
            text: t('communication.posts'),
            accessKey: ACCESS_KEYS.PAGES.COMMUNICATION.HOME_CLUB_POSTS,
            to: '/communication/home-club-posts',
          },
          {
            text: t('communication.bookingEmail'),
            accessKey: ACCESS_KEYS.PAGES.COMMUNICATION.BOOKINGS_EMAIL,
            to: '/communication/bookings-email',
          },
        ],
      },
      {
        text: t('vouchers.text'),
        activeIconFaClassName: 'fa-solid fa-gift',
        inactiveIconFaClassName: 'fa-light fa-gift',
        to: '/vouchers',
        accessKey: ACCESS_KEYS.PAGES.VOUCHERS.READ,
        onClick: () => track(AMPLITUDE_EVENTS.VOUCHERS_TAPPED),
      },
      {
        text: t('sideMenu.menuItems.partnership.comingSoon'),
        activeIconFaClassName: 'fa-solid fa-handshake',
        inactiveIconFaClassName: 'fa-light fa-handshake',
        to: '/partnerships',
        accessKey: ACCESS_KEYS.PAGES.PARTNERSHIPS,
        onClick: () => track(AMPLITUDE_EVENTS.PARTNERSHIPS_TAPPED),
      },
      {
        text: t('sideMenu.menuItems.statistics.comingSoon'),
        activeIconFaClassName: 'fa-solid fa-chart-simple',
        inactiveIconFaClassName: 'fa-light fa-chart-simple',
        accessKey: ACCESS_KEYS.PAGES.STATISTICS,
        onClick: () => track(AMPLITUDE_EVENTS.STATISTICS_TAPPED),
        subItems: [
          {
            text: t('sideMenu.menuItems.overview'),
            to: '/statistics',
          },
          {
            text: t('sideMenu.menuItems.teeSheet.text'),
            to: '/statistics/tee-sheet',
          },
          {
            text: t('sideMenu.menuItems.bookings.text'),
            to: '/statistics/bookings',
          },
          {
            text: t('sideMenu.menuItems.membership.text'),
            to: '/statistics/memberships',
          },
          {
            text: t('vouchers.title'),
            to: '/statistics/vouchers',
          },
          {
            text: t('sideMenu.menuItems.partnership.text'),
            to: '/statistics/partnerships',
          },
          {
            text: t('tags'),
            to: '/statistics/tags',
          },
        ],
      },
      {
        text: t('sideMenu.menuItems.insights.text'),
        activeIconFaClassName: 'fa-solid fa-lightbulb-on',
        inactiveIconFaClassName: 'fa-light fa-lightbulb-on',
        accessKey: ACCESS_KEYS.PAGES.INSIGHTS.MENU,
        onClick: () => track(AMPLITUDE_EVENTS.INSIGHTS_TAPPED),
        subItems: [
          {
            text: t('sideMenu.menuItems.insights.subItems.clubOverview'),
            to: '/insights/club-overview',
            accessKey: ACCESS_KEYS.PAGES.INSIGHTS.CLUB_OVERVIEW,
          },
          {
            text: t('sideMenu.menuItems.insights.subItems.golfCourses'),
            to: '/insights/club-overview/golf-courses',
            accessKey: ACCESS_KEYS.PAGES.INSIGHTS.GOLF_COURSES,
          },
          {
            text: t('sideMenu.menuItems.insights.subItems.simulators'),
            to: '/insights/club-overview/simulators',
            accessKey: ACCESS_KEYS.PAGES.INSIGHTS.SIMULATORS,
          },
          {
            text: t('sideMenu.menuItems.insights.subItems.discountBreakdown'),
            to: '/insights/club-overview/discount-breakdown',
            accessKey: ACCESS_KEYS.PAGES.INSIGHTS.DISCOUNT_BREAKDOWN,
          },
          {
            text: t('sideMenu.menuItems.insights.subItems.occupancy'),
            to: '/insights/club-overview/occupancy',
            accessKey: ACCESS_KEYS.PAGES.INSIGHTS.OCCUPANCY,
          },
          {
            text: t('sideMenu.menuItems.insights.subItems.membershipsAndMembers'),
            to: '/insights/club-overview/membership-and-members',
            accessKey: ACCESS_KEYS.PAGES.INSIGHTS.MEMBERSHIPS_AND_MEMBERS,
          },
          {
            text: t('sideMenu.menuItems.insights.subItems.membershipSales'),
            to: '/insights/membership-sales',
            accessKey: ACCESS_KEYS.PAGES.INSIGHTS.MEMBERSHIP_SALES,
          },
          {
            text: t('sideMenu.menuItems.insights.subItems.partnerships'),
            to: '/insights/club-overview/partnership',
            accessKey: ACCESS_KEYS.PAGES.INSIGHTS.PARTNERSHIPS,
          },
          {
            text: t('sideMenu.menuItems.insights.subItems.rangeDispenser'),
            to: '/insights/range-dispenser',
            accessKey: ACCESS_KEYS.PAGES.INSIGHTS.RANGE_DISPENSER,
          },
        ],
      },
      {
        text: t('reports.text'),
        activeIconFaClassName: 'fa-solid fa-file-spreadsheet',
        inactiveIconFaClassName: 'fa-light fa-file-spreadsheet',
        to: '/reports',
        accessKey: ACCESS_KEYS.PAGES.REPORTS,
        onClick: () => track(AMPLITUDE_EVENTS.REPORTS_TAPPED),
      },
      {
        text: t('sideMenu.menuItems.accounting.text'),
        activeIconFaClassName: 'fa-solid fa-calculator-simple',
        inactiveIconFaClassName: 'fa-light fa-calculator-simple',
        to: '/insights/transactions-and-payouts',
        accessKey: ACCESS_KEYS.PAGES.INSIGHTS.TRANSACTIONS,
        onClick: () => track(AMPLITUDE_EVENTS.REPORTS_TAPPED),
      },
    ],
    bottomNav: [
      {
        text: t('settings.settings'),
        activeIconFaClassName: 'fa-solid fa-cog',
        inactiveIconFaClassName: 'fa-light fa-cog',
        subItems: [
          {
            text: t('settings.golfclubs'),
            subItems: [
              {
                text: t('settings.createGolfclub'),
                to: '/settings/create-club',
                accessKey: ACCESS_KEYS.SETTINGS.GOLF_CLUB.CREATE,
              },
              {
                text: t('settings.modifyGolfclub'),
                to: '/settings/edit-club',
                accessKey: ACCESS_KEYS.SETTINGS.GOLF_CLUB.MODIFY,
              },
              {
                text: t('settings.deleteGolfclub'),
                to: '/settings/delete-club',
                accessKey: ACCESS_KEYS.SETTINGS.GOLF_CLUB.DELETE,
              },
            ],
          },
          {
            text: t('settings.golfCourses'),
            subItems: [
              {
                text: t('settings.createGolfcourse'),
                to: '/settings/create-course',
                accessKey: ACCESS_KEYS.SETTINGS.GOLF_COURSE.CREATE,
              },
              {
                text: t('settings.modifyGolfcourse'),
                to: '/settings/edit-course',
                accessKey: ACCESS_KEYS.SETTINGS.GOLF_COURSE.MODIFY,
              },
              {
                text: t('settings.deleteGolfcourse'),
                to: '/settings/delete-course',
                accessKey: ACCESS_KEYS.SETTINGS.GOLF_COURSE.DELETE,
              },
            ],
          },
          {
            text: t('words.spaces'),
            subItems: [
              {
                text: t('sentences.createSpace'),
                to: '/settings/create-space',
                accessKey: ACCESS_KEYS.SETTINGS.SPACES.CREATE,
              },
              {
                text: t('sentences.modifySpace'),
                to: '/settings/edit-space',
                accessKey: ACCESS_KEYS.SETTINGS.SPACES.MODIFY,
              },
              {
                text: t('sentences.deleteSpace'),
                to: '/settings/delete-space',
                accessKey: ACCESS_KEYS.SETTINGS.SPACES.DELETE,
              },
            ],
          },
          ...(options.isRangeBayEnabled
            ? [
                {
                  text: t('words.drivingRange'),
                  subItems: [
                    {
                      text: t('settings.settings'),
                      to: '/settings/manage-range',
                      accessKey: ACCESS_KEYS.SETTINGS.DRIVING_RANGE.MANAGE_RANGE,
                    },
                    {
                      text: t('words.bays'),
                      to: '/settings/bays',
                      accessKey: ACCESS_KEYS.SETTINGS.DRIVING_RANGE.BAYS,
                    },
                    {
                      text: t('sentences.ballDispensers'),
                      to: '/settings/ball-dispensers',
                      accessKey: ACCESS_KEYS.SETTINGS.DRIVING_RANGE.BALL_DISPENSERS,
                    },
                    {
                      text: t('sentences.ballPricing'),
                      to: '/settings/ball-pricing',
                      accessKey: ACCESS_KEYS.SETTINGS.DRIVING_RANGE.BALL_PRICING,
                    },
                  ],
                },
              ]
            : []),
          {
            text: t('editPeriodsNew.teeTimePeriods'),
            to: '/settings/tee-time-periods',
            accessKey: ACCESS_KEYS.SETTINGS.TEE_TIME_PERIODS,
          },
          {
            text: t('teeTimeCategory.title'),
            to: '/settings/tee-time-category',
            accessKey: ACCESS_KEYS.SETTINGS.TEE_TIME_CATEGORIES,
          },
          {
            text: t('words.promotions'),
            to: '/settings/promotions',
            accessKey: ACCESS_KEYS.SETTINGS.PROMOTIONS,
          },
          {
            text: t('cooperations.title'),
            to: '/settings/cooperations',
            accessKey: ACCESS_KEYS.SETTINGS.PRICING,
          },
          {
            text: t('sideMenu.menuItems.pricing.text'),
            to: '/settings/pricing',
            accessKey: ACCESS_KEYS.SETTINGS.PRICING,
          },
          {
            text: t('bills'),
            to: '/settings/billing-settings',
            accessKey: ACCESS_KEYS.SETTINGS.BILLING.SETTINGS,
          },
          {
            text: t('settings.webBookings.title'),
            to: '/settings/web-booking',
            accessKey: ACCESS_KEYS.SETTINGS.WEB_BOOKING,
          },
          {
            text: t('settings.courseGuide.courseGuide'),
            to: '/settings/course-guides',
            accessKey: ACCESS_KEYS.SETTINGS.COURSE_GUIDES,
          },
          {
            text: t('settings.homeClub.title'),
            to: '/settings/home-club',
            accessKey: ACCESS_KEYS.SETTINGS.HOMECLUB,
          },
          {
            text: t('sentences.clubUsers'),
            to: '/settings/club-users',
            accessKey: ACCESS_KEYS.SETTINGS.CLUB_USERS.READ,
          },
          {
            text: t('sideMenu.menuItems.settings.subItems.appUsers'),
            to: '/app-users',
            accessKey: ACCESS_KEYS.SETTINGS.APP_USERS,
          },
          {
            text: t('sentences.qtIntegration'),
            to: '/settings/qt-integration',
            accessKey: ACCESS_KEYS.SETTINGS.QT_INTEGRATION,
          },
          {
            text: t('Tags'),
            subItems: [
              {
                text: t('settings.createTag'),
                to: '/settings/create-tag',
                accessKey: ACCESS_KEYS.SETTINGS.TAGS.CREATE,
              },
              {
                text: t('settings.modifyTag'),
                to: '/settings/edit-tag',
                accessKey: ACCESS_KEYS.SETTINGS.TAGS.MODIFY,
              },
              {
                text: t('settings.deleteTag'),
                to: '/settings/delete-tag',
                accessKey: ACCESS_KEYS.SETTINGS.TAGS.DELETE,
              },
            ],
          },
          {
            text: t('sideMenu.menuItems.settings.subItems.statusPage'),
            to: 'https://sweetspot.statuspage.io/',
            accessKey: ACCESS_KEYS.SETTINGS.STATUS_PAGE,
            externalLink: true,
          },
          {
            text: t('settings.users.users'),
            subItems: [
              {
                text: t('partnerRoles'),
                to: '/settings/partner-admins',
                accessKey: ACCESS_KEYS.SETTINGS.USERS.PARTNER_ADMINS,
              },
              {
                text: t('applications.title_SA'),
                to: '/settings/partner-integrations',
                accessKey: ACCESS_KEYS.SETTINGS.USERS.PARTNER_INTEGRATIONS,
              },
              // -------------- COMMENTED BUT NOT DELETED ------------
              // {
              //   text: 'settings.clubAdmins.text',
              //   to: '/settings/sa/club-users',
              //   accessKey: ACCESS_KEYS.SETTINGS.USERS.CLUB_ADMINS,
              // },
            ],
          },
        ],
      },
      {
        text: t('sentences.signOut'),
        activeIconFaClassName: 'fa-solid fa-arrow-right-from-bracket',
        inactiveIconFaClassName: 'fa-light fa-arrow-right-from-bracket',
        onClick: () => handleLogout(),
      },
    ],
  }
}

const SideNav = ({ roles, mode, logout, golfCourses, currentCourse }) => {
  const [currentUserRole, setCurrentUserRole] = useState(null)
  const { t } = useTranslation()

  // Prefetch is not working as expected in first render thus using useQuery to fetch failed payments
  const { data: failedPayments } = useQuery({
    queryFn: () => getFailedMembershipPayments(),
    queryKey: [PAYMENT_QUERY_KEYS.FAILED_PAYMENT, 1, []],
    select: (data) => data?.['hydra:member'],
  })

  useEffect(() => {
    const role = getFirstMatchingRole(roles)?.value
    setCurrentUserRole(role)
    prefetch()
  }, [roles])

  const handleLogout = () => {
    sessionList.forEach((item) => delDataPersistent(item))
    logout()
  }

  const prefetch = async () => {
    await queryClient.prefetchQuery({
      queryFn: () => getFailedMembershipPayments(),
      queryKey: [PAYMENT_QUERY_KEYS.FAILED_PAYMENT, 1, []],
    })
  }

  const isCoursesAvailable = useMemo(
    () =>
      currentCourse?.type === COURSE_TYPES.COURSE.value ||
      golfCourses.some((x) => x?.type === COURSE_TYPES.COURSE.value),
    [currentCourse, golfCourses]
  )

  const isRangeBayEnabled = useFlag(FlagNames.RangeBays)

  const isMaxFailedAttempts = checkForMaxFailedAttempts(failedPayments)

  const getNavItems = () => {
    switch (mode) {
      case 'pro':
        return []
      case 'club':
      default:
        return getClubNavItems(
          { isCoursesAvailable, isRangeBayEnabled, isMaxFailedAttempts },
          handleLogout,
          t
        )
    }
  }

  const filterNavItems = (navItems, currentUserRole) => {
    return navItems
      .map((navItem) => {
        const filteredSubItems = navItem.subItems?.filter((item) => {
          return item?.accessKey && currentUserRole
            ? hasAccess(item.accessKey, currentUserRole)
            : true
        })

        if (navItem.accessKey) {
          return hasAccess(navItem.accessKey, currentUserRole)
            ? { ...navItem, subItems: filteredSubItems }
            : null
        }

        if (navItem.subItems) {
          return filteredSubItems.length > 0 ? { ...navItem, subItems: filteredSubItems } : null
        }

        return navItem
      })
      .filter(Boolean)
  }

  const mapBottomNavItems = (navItems, currentUserRole) => {
    return navItems.map((navItem) => {
      const mappedSubItems = mapSubItems(navItem.subItems, currentUserRole)

      return {
        ...navItem,
        disabled: false,
        subItems: mappedSubItems,
      }
    })
  }

  const mapSubItems = (subItems, currentUserRole) => {
    if (!subItems) return null

    const mappedSubItems = subItems.map((item) => {
      const { accessKey, ...rest } = item
      const disabled = accessKey ? !hasAccess(accessKey, currentUserRole) : false
      const nestedMappedSubItems = mapSubItems(item.subItems, currentUserRole)

      const allSubItemsDisabled =
        nestedMappedSubItems?.length > 0 && nestedMappedSubItems.every((item) => item.disabled)

      return {
        ...rest,
        disabled: allSubItemsDisabled ? true : disabled,
        subItems: nestedMappedSubItems,
      }
    })

    return mappedSubItems
  }

  const navItems = filterNavItems(getNavItems().topNav, currentUserRole)
  const bottomNavItems = mapBottomNavItems(getNavItems().bottomNav, currentUserRole)

  return (
    <SideSystemNav className="z-10">
      {navItems.map((navItem) => {
        return (
          <WrappedTopLevelItem
            to={navItem?.to}
            key={navItem.text}
            text={navItem.text}
            onClick={navItem?.onClick}
            activeIconFaClassName={navItem.activeIconFaClassName}
            inactiveIconFaClassName={navItem.inactiveIconFaClassName}
            externalLink={navItem?.externalLink}
            disabled={navItem?.disabled}
          >
            {navItem?.subItems?.map((subItem) => (
              <WrappedSecondLevelItem
                key={subItem.text}
                to={subItem?.to}
                text={subItem.text}
                onClick={subItem?.onClick}
                externalLink={subItem?.externalLink}
                showAlertBadge={subItem?.showAlertBadge}
                disabled={subItem?.disabled}
              >
                {subItem?.subItems?.map((thirdLevelItem) => (
                  <WrappedThirdLevelItem
                    key={thirdLevelItem.text}
                    to={thirdLevelItem?.to}
                    text={thirdLevelItem.text}
                    onClick={thirdLevelItem?.onClick}
                    externalLink={thirdLevelItem?.externalLink}
                    disabled={thirdLevelItem?.disabled}
                  />
                ))}
              </WrappedSecondLevelItem>
            ))}
          </WrappedTopLevelItem>
        )
      })}
      <BottomNavContent>
        {bottomNavItems.map((navItem) => {
          return (
            <WrappedTopLevelItem
              to={navItem?.to}
              key={navItem.text}
              text={navItem.text}
              onClick={navItem?.onClick}
              activeIconFaClassName={navItem.activeIconFaClassName}
              inactiveIconFaClassName={navItem.inactiveIconFaClassName}
              externalLink={navItem?.externalLink}
              disabled={navItem?.disabled}
            >
              {navItem?.subItems?.map((subItem) => (
                <WrappedSecondLevelItem
                  key={subItem.text}
                  to={subItem?.to}
                  text={subItem.text}
                  onClick={subItem?.onClick}
                  externalLink={subItem?.externalLink}
                  disabled={subItem?.disabled}
                >
                  {subItem?.subItems?.map((thirdLevelItem) => (
                    <WrappedThirdLevelItem
                      key={thirdLevelItem.text}
                      to={thirdLevelItem?.to}
                      text={thirdLevelItem.text}
                      onClick={thirdLevelItem?.onClick}
                      externalLink={thirdLevelItem?.externalLink}
                      disabled={thirdLevelItem?.disabled}
                    />
                  ))}
                </WrappedSecondLevelItem>
              ))}
            </WrappedTopLevelItem>
          )
        })}
      </BottomNavContent>
    </SideSystemNav>
  )
}

SideNav.propTypes = {
  history: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
  mode: PropTypes.string.isRequired,
  shouldUpdate: PropTypes.bool,
  logout: PropTypes.func.isRequired,
  golfCourses: PropTypes.array.isRequired,
  currentCourse: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
  return {
    roles: state.auth.roles,
    mode: state.appState.mode,
    golfCourses: state.golfCourse.list,
    currentCourse: state.golfCourse.list.find((c) => c.id === state.golfCourse.selectedId),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SideNav)
