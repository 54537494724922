import { useState, useEffect, useMemo, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import produce from 'immer'

import promiseForAsync from '@sweetspot/club-portal-legacy/helpers/PromiseForAsync'
import Utils from '@sweetspot/club-portal-legacy/helpers/Utilities'

import usePrevious from '@sweetspot/club-portal-legacy/hooks/usePrevious'
import useTranslation from '@sweetspot/club-portal-legacy/hooks/useTranslation'

import {
  getPartnershipById,
  getMembershipById,
  getPartnerships,
  addPartnership,
  addMembership,
  updatePartnership,
  updateMembership,
  deleteMembership,
  deletePartnership,
  deleteDraftMembership,
  cancelPartnership,
  activatePartnership,
  publishMembership,
  deletePromotion,
  updatePromotion,
  addPromotionToMembership,
  addPromotionToPartnership,
  addRuleToPromotion,
  updateRule,
  deleteRule,
  addActionToPromotion,
  updateAction,
  deleteAction,
} from '@sweetspot/club-portal-legacy/store/actions'
import { RULE_TYPES } from '@sweetspot/club-portal-legacy/components/Wizard/constants'
import { useMutation } from 'react-query'
import { migrateAutorenewalSettings } from '@sweetspot/shared/data-access/api-platform'

let canSaveTimeout = null

const types = {
  partnership: 'partnership',
  membership: 'membership',
}

/**
 *
 *
 * @param {object} {
 *   defaultEntity,
 *   type,
 *   entityIdParamKey,
 *   validateOnNew,
 *   validateOnUpdate,
 *   validatePromotions,
 *   reviewStep
 * }
 * @returns
 */
const useWizard = ({
  defaultEntity,
  type,
  entityIdParamKey,
  reviewStep,
  rulesStep,
  validateOnNew,
  validateOnUpdate,
  validatePromotions,
  validateOnActivate,
  handleCloseModal,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const lang = useSelector((state) => state.auth.me.lang)
  const token = useSelector((state) => state.auth.token)
  const { addToast } = useToasts()

  const history = useHistory()
  const params = useParams()

  /********************************/
  /******* Memoized Values ********/
  /********************************/
  const collectionUrl = useMemo(() => {
    if (type === 'partnership') {
      return 'partnerships'
    }
    if (type === 'membership') {
      return 'memberships'
    }
  }, [type])

  const reviewStepMemo = useMemo(() => reviewStep, [reviewStep])
  const rulesStepMemo = useMemo(() => rulesStep, [rulesStep])

  /********************************/
  /*********** State **************/
  /********************************/
  const [currentStep, setCurrentStep] = useState(1)
  const [loading, setLoading] = useState(true)
  const [canSave, setCanSave] = useState(type === 'partnership' ? true : false)

  const [currentEntity, setCurrentEntity] = useState(defaultEntity)
  const prevEntity = usePrevious(currentEntity)
  const [currentEntityId, setCurrentEntityId] = useState(null)

  const [values, setValues] = useState(defaultEntity)
  const prevValues = usePrevious(values)
  const [errors, setErrors] = useState({})
  const [globalErrors, setGlobalErrors] = useState([])

  /********************************************************/
  /*********** Type conditioned CRUD API Methods **********/
  /********************************************************/
  const getEntityById = useCallback(
    (id) => {
      if (type === types.partnership) {
        return dispatch(getPartnershipById(id))
      }
      if (type === types.membership) {
        return dispatch(getMembershipById(id))
      }
    },
    [type, dispatch]
  )

  const createEntity = useCallback(
    (payload) => {
      if (type === types.partnership) {
        return dispatch(addPartnership(payload))
      }
      if (type === types.membership) {
        return dispatch(addMembership(payload))
      }
    },
    [type, dispatch]
  )

  const updateEntity = useCallback(
    (id, payload) => {
      if (type === types.partnership) {
        return dispatch(updatePartnership(id, payload))
      }
      if (type === types.membership) {
        return dispatch(updateMembership(id, payload))
      }
    },
    [type, dispatch]
  )

  const activateEntity = useCallback(() => {
    if (type === types.partnership) {
      return dispatch(activatePartnership(currentEntityId))
    }
    if (type === types.membership) {
      return dispatch(publishMembership(currentEntityId))
    }
  }, [currentEntityId, type, dispatch])

  const deleteEntity = useCallback(() => {
    if (type === types.partnership) {
      return dispatch(deletePartnership(currentEntityId))
    }
    if (type === types.membership && currentEntity?.state === 'draft') {
      return dispatch(deleteDraftMembership(currentEntityId))
    } else return dispatch(deleteMembership(currentEntityId))
  }, [currentEntityId, type, dispatch])

  const cancelEntity = useCallback(() => {
    if (type === types.partnership) {
      return dispatch(cancelPartnership(currentEntityId))
    }
    if (type === types.membership) {
      // TODO: Implement soft-delete for membership
    }
  }, [currentEntityId, type, dispatch])

  const createPromotion = useCallback(
    (payload) => {
      if (type === types.partnership) {
        return dispatch(addPromotionToPartnership(currentEntityId, payload))
      }
      if (type === types.membership) {
        return dispatch(addPromotionToMembership(currentEntityId, payload))
      }
    },
    [currentEntityId, type, dispatch]
  )

  const { mutate: migrateAutorenewalSettingsMutation } = useMutation((membershipId) =>
    migrateAutorenewalSettings(membershipId)
  )

  /*************************/
  /******* Methods *********/
  /*************************/
  // Function to deep compare two objects and check if they are different
  function hasObjectChanged(obj, prevState) {
    // Helper function to perform a deep comparison between two objects
    function deepEqual(a, b) {
      // If both are the same object, or both are strictly equal (including primitive values)
      if (a === b) return true

      // If either is not an object or is null, return false
      if (typeof a !== 'object' || a === null || typeof b !== 'object' || b === null) {
        return false
      }

      // Compare the number of keys in each object
      const keysA = Object.keys(a)
      const keysB = Object.keys(b)
      if (keysA.length !== keysB.length) return false

      // Recursively compare all properties
      for (let key of keysA) {
        if (!keysB.includes(key) || !deepEqual(a[key], b[key])) {
          return false
        }
      }

      return true
    }

    // Compare the current object with the previous state
    return !deepEqual(obj, prevState)
  }

  /**
   * Create new membership or partnership
   */
  const createNewEntity = (callback) => {
    validateOnNew(values, lang)
      .then(async (payload) => {
        setLoading(true)

        const [res, err] = await promiseForAsync(createEntity(payload))

        if (err) {
          const { detail, violations } = err

          if (
            detail === 'partnership: Partnership with this name already exists in the club' ||
            detail === 'name: Membership name must be unique per club.' ||
            violations?.find(
              (x) =>
                x?.errorName === 'UNIQUE_ERROR' ||
                x?.errorName === 'MEMBERSHIP_UNIQUENESS_ERROR' ||
                x?.errorName === 'PARTNERSHIP_UNIQUENESS_ERROR'
            )
          ) {
            if (type === types.membership) {
              updateError('name', t('errors.youAlreadyHaveAMembershipWithName'))
            }
            if (type === types.partnership) {
              updateError('name', t('errors.youAlreadyHaveAPartnershipWithName'))
            }
          }

          setLoading(false)
          setCanSave(false)
          addGlobalError('validation-error')
        }

        if (res) {
          const { id } = res
          if (type === types.partnership) {
            incrementStep()
          }
          history.replace(`/${collectionUrl}/${id}`)
          prepareData(id)
          if (callback) callback()
        }
      })
      .catch((errors) => {
        if (errors) errors.forEach((err) => updateError(err.path, err.message))
        setCanSave(false)
        addGlobalError('validation-error')
      })
  }

  /**
   * Update existing membership or partnership
   */
  const updateExistingEntity = (callback) => {
    validateOnUpdate(values, lang)
      .then(async (payload) => {
        setLoading(true)

        const [res, err] = await promiseForAsync(updateEntity(currentEntityId, payload))

        if (err) {
          const { detail, violations } = err
          if (
            detail === 'partnership: Partnership with this name already exists in the club' ||
            detail === 'name: Membership name must be unique per club.' ||
            violations?.find(
              (x) =>
                x?.errorName === 'UNIQUE_ERROR' ||
                x?.errorName === 'MEMBERSHIP_UNIQUENESS_ERROR' ||
                x?.errorName === 'PARTNERSHIP_UNIQUENESS_ERROR'
            )
          ) {
            if (type === types.membership) {
              updateError('name', t('errors.youAlreadyHaveAMembershipWithName'))
            }
            if (type === types.partnership) {
              updateError('name', t('errors.youAlreadyHaveAPartnershipWithName'))
            }
          }

          setLoading(false)
          return
        }
        if (res) {
          if (
            type === types.partnership &&
            (res.state === 'created' || res.state === 'draft' || res.state === 'enabled')
          ) {
            incrementStep()
          }
          if (type === types.partnership) prepareData()
          else {
            if (type === types.membership) setCurrentEntity({ ...values })
            setLoading(false)
          }
          if (callback) callback(res)
        }
      })
      .catch((errors) => {
        errors.forEach((err) => updateError(err.path, err.message))
        setCanSave(false)
        addGlobalError('validation-error')
      })
  }

  /**
   * Cancels entity
   * Soft delete
   */
  const cancelExistingEntity = async (callback) => {
    setLoading(true)
    const [res, err] = await promiseForAsync(cancelEntity())

    if (res) {
      if (callback) callback(true)
    }
    if (err) {
      if (callback) callback(false)
    }
    setLoading(false)
  }

  /**
   * Delete entity
   */
  const deleteExistingEntity = async (callback) => {
    setLoading(true)
    const [res, err] = await promiseForAsync(deleteEntity())

    if (res) {
      if (callback) callback(true)
    }
    if (err) {
      if (callback) callback(false)
    }
  }

  /**
   * Activate / Publish entity
   */
  const activateExistingEntity = (callback) => {
    validateOnActivate(values, currentEntity, lang)
      .then(async () => {
        setLoading(true)
        const [res, err] = await promiseForAsync(activateEntity())

        if (res) {
          if (callback) callback(true)
        }
        if (err) {
          if (type === types.membership) addGlobalError('publish-membership')
          if (callback) callback(false)
        }
        prepareData()
      })
      .catch((errors) => {
        errors.forEach((err) => {
          if (
            err.path === 'fees.fees' ||
            err.path === 'fees' ||
            err.message === 'feesMustBeSaved'
          ) {
            addGlobalError('fees-required')
          } else if (
            err.path === 'promotions.promotions' ||
            err.message === 'promotionsMustBeSaved'
          ) {
            addGlobalError('promotions-required')
          } else if (err.message === 'invalidRulesForPartnershipType') {
            addGlobalError('invalid-rules-for-partnership')
          } else {
            updateError(err.path, err.message)
            addGlobalError('validation-error')
          }
        })
        setCanSave(false)
        if (callback) callback(false)
      })
  }

  const migrateMembershipAutorenewalSettings = (membershipId) => {
    setLoading(true)
    migrateAutorenewalSettingsMutation(membershipId, {
      onSettled: () => {
        setLoading(false)
        prepareData()
      },
    })
  }

  /**
   * Save promotions, rules and actions.
   * Will CRUD promotions
   * Will CRUD rules
   * Will CRUD actions
   */
  const savePromotions = (callback) => {
    const { promotions } = values

    validatePromotions(promotions, lang)
      .then(async (payload) => {
        setLoading(true)

        Object.keys(currentEntity.promotions).forEach(async (key) => {
          if (!payload[key]) await dispatch(deletePromotion(key))
        })

        const promotionsArray = Utils.objectToArray(payload)

        const [res, err] = await promiseForAsync(
          Promise.all(
            promotionsArray.map(async (promotion) => {
              let { id: promotionId, name, rules, actions } = promotion

              // If Promotion is new
              if (
                (typeof promotionId === 'string' && promotionId.includes('tempId')) ||
                !currentEntity.promotions[promotionId]
              ) {
                const [newPromotion, newPromotionErr] = await promiseForAsync(
                  createPromotion({ name })
                )
                if (newPromotionErr) throw newPromotionErr
                if (newPromotion && newPromotion.id) promotionId = newPromotion.id
                else throw new Error('New Promotion ID undefined')

                // Add rules and actions to new promotion
                await Promise.all([
                  ...Object.keys(rules).map((key) =>
                    dispatch(addRuleToPromotion(promotionId, rules[key]))
                  ),
                  ...Object.keys(actions).map((key) =>
                    dispatch(addActionToPromotion(promotionId, actions[key]))
                  ),
                ]).catch((err) => {
                  throw err
                })
                return true
              }

              // Get old values
              let {
                name: nameOld,
                rules: rulesOld,
                actions: actionsOld,
              } = currentEntity.promotions[promotionId]
              rulesOld = { ...rulesOld }
              actionsOld = { ...actionsOld }

              // If promotion has changed name
              if (name !== nameOld) {
                await dispatch(updatePromotion(promotionId, { name }))
              }

              // Delete removed rules
              await Promise.all(
                Object.keys(rulesOld).map((key) => {
                  const oldRule = { ...rulesOld[key] }
                  const newRule = rules[key]
                  if (!newRule || oldRule.id !== newRule.id) {
                    rulesOld[key] = null
                    return dispatch(deleteRule(oldRule.id))
                  }
                  return true
                })
              )

              // Delete removed actions
              await Promise.all(
                Object.keys(actionsOld).map((key) => {
                  const oldAction = { ...actionsOld[key] }
                  const newAction = actions[key]
                  if (!newAction || oldAction.id !== newAction.id) {
                    actionsOld[key] = null
                    return dispatch(deleteAction(oldAction.id))
                  }
                  return true
                })
              )

              // Update rules and actions, and add new
              await Promise.all([
                ...Object.keys(rules).map(async (key) => {
                  const rule = rules[key]
                  const { type, id, configuration } = rule

                  // Add new
                  if (!rulesOld[type]) {
                    return dispatch(addRuleToPromotion(promotionId, rule))
                  }

                  const { configuration: configurationOld } = rulesOld[type]

                  if (JSON.stringify(configuration) === JSON.stringify(configurationOld)) return

                  return dispatch(updateRule(id, rule))
                }),
                ...Object.keys(actions).map(async (key) => {
                  const action = actions[key]
                  const { type, id, configuration } = action

                  if (!actionsOld[type]) {
                    return dispatch(addActionToPromotion(promotionId, action))
                  }

                  const { configuration: configurationOld } = actionsOld[type]

                  if (JSON.stringify(configuration) === JSON.stringify(configurationOld)) return

                  return dispatch(updateAction(id, action))
                }),
              ]).catch((err) => {
                throw err
              })

              return true
            })
          )
        )

        if (err) {
          setLoading(false)
          return
        }

        if (res) {
          if (type === types.membership && currentEntity.state === 'published') {
            // TODO: Add sync membership dispatch
          }
          if (currentEntity.state === 'draft' || currentEntity.state === 'created') {
            incrementStep()
          }
          prepareData()
          if (callback) callback()
          return
        }
      })
      .catch((errors) => {
        errors.forEach((err) => updateError(`promotions.${err.path}`, err.message))
        setCanSave(false)
        addGlobalError('validation-error')
      })
  }

  /**********************************/
  /*********** Methods **************/
  /**********************************/

  /**
   * Normalizes entity. Aka = converts some arrays to objects for easier value storing and updating
   */
  const normalizeEntity = useCallback(
    (entity) => {
      if (type === types.partnership) {
        return {
          ...entity,
          promotions: Utils.arrayToObject(
            (entity.promotions || [])
              .sort((a, b) => a.priority - b.priority)
              .map((promotion) => ({
                ...promotion,
                rules: Utils.arrayToObject(promotion.rules, 'type'),
                actions: Utils.arrayToObject(promotion.actions, 'type'),
              }))
          ),
        }
      }
      if (type === types.membership) {
        return {
          ...entity,
          promotions: Utils.arrayToObject(
            (entity.promotions || [])
              .sort((a, b) => a.priority - b.priority)
              .map((promotion) => ({
                ...promotion,
                rules: Utils.arrayToObject(promotion.rules, 'type'),
                actions: Utils.arrayToObject(promotion.actions, 'type'),
              }))
          ),
        }
      }
    },
    [type]
  )

  /**
   * Prepares everything needed to init wizard.
   * Loads some data etc.
   * Is run on mount
   */
  const prepareData = async (id) => {
    let entityId = null
    if (params && params[entityIdParamKey]) {
      entityId = params[entityIdParamKey]
    }
    if (id) {
      entityId = id
    }

    setLoading(true)

    if (!entityId || entityId === 'new') {
      setLoading(false)
      if (type === types.partnership) setCurrentStep(1)
      return
    }

    entityId = parseInt(entityId)

    setCurrentEntityId(entityId)

    let [entity, err] = await promiseForAsync(getEntityById(entityId))

    if (!entity || err) {
      addToast(
        type === types.membership
          ? t('wizard.membershipDoesNotExist')
          : t('wizard.partnershipDoesNotExist'),
        { appearance: 'error' }
      )
      handleCloseModal()
      return
    }

    const tempId = crypto.randomUUID()

    if (type === types.partnership) {
      if ((!entity.promotions || entity.promotions.length <= 0) && entity.state === 'created') {
        if (!entity.promotions) {
          entity.promotions = []
        }
        entity.promotions.push({
          ...defaultEntity.promotions[0],
          id: `tempId_${tempId}`,
          name: tempId,
        })
      }
    }

    const normalizedEntity = normalizeEntity(entity)

    setCurrentEntity(normalizedEntity)
    setValues(normalizedEntity)
    setLoading(false)
    setCanSave(false)
  }

  /**********************************/
  /*********** Effects **************/
  /**********************************/

  /**
   * Runs on mount and triggers init/preparation
   */
  useEffect(() => {
    prepareData()
    // eslint-disable-next-line
  }, [])

  /**
   * Removes global error when any value is updated
   */
  useEffect(() => {
    if (globalErrors.length) removeGlobalError('validation-error')
  }, [values, globalErrors.length])

  /**
   * Checks if anything is saved from original or default entity
   */
  useEffect(() => {
    if (values && prevValues) {
      clearTimeout(canSaveTimeout)
      canSaveTimeout = setTimeout(() => {
        if (Utils.objectAreEqualAdvanced(values, prevValues)) {
          setCanSave(false)
        } else {
          setCanSave(true)
          if (
            type === 'membership' &&
            values.name === currentEntity.name &&
            currentStep === 1 &&
            hasObjectChanged(values, currentEntity)
          ) {
            updateExistingEntity()
          }
        }
      }, 375)
    }

    return () => {
      clearTimeout(canSaveTimeout)
    }
  }, [values, currentEntity])

  useEffect(() => {
    if (prevEntity && currentEntity && prevEntity.state !== currentEntity.state) {
      if (type === 'partnership' && currentEntity.state !== 'created') {
        setCurrentStep(reviewStepMemo)
      }
    }
  }, [prevEntity, currentEntity, reviewStepMemo, type])

  useEffect(() => {
    if (currentStep === rulesStepMemo) {
      removeGlobalError('promotions-required')
      removeGlobalError('invalid-rules-for-partnership')
    }
  }, [currentStep, rulesStepMemo])

  /**********************************/
  /****** State Methods & Utilis ****/
  /**********************************/
  const incrementStep = () => {
    setCurrentStep((prevStep) => prevStep + 1)
  }

  const decrementStep = () => {
    setCurrentStep((prevStep) => prevStep - 1)
  }

  const setStep = (step) => {
    setCurrentStep(step)
  }

  const updateError = (key, value) => {
    setErrors(
      produce((draft) => {
        draft[key] = value
      })
    )
  }

  const removeGlobalError = (value) => {
    setGlobalErrors(
      produce((draft) => {
        return draft.filter((err) => err !== value)
      })
    )
  }

  const addGlobalError = (value) => {
    setGlobalErrors(
      produce((draft) => {
        if (!draft.includes(value)) {
          draft.push(value)
        }
      })
    )
  }

  const clearGlobalErrors = () => {
    setGlobalErrors([])
  }

  const setEntityValue = (key, value) => {
    if (
      values?.membership_member_limit?.is_active === false &&
      values?.membership_member_limit?.max_members === 0 &&
      values?.membership_member_limit?.current_members > 0
    ) {
      setValues(
        produce((draft) => {
          draft['membership_member_limit'] = {
            ...values?.membership_member_limit,
            max_members: values?.membership_member_limit?.current_members,
          }
        })
      )
    }

    setValues(
      produce((draft) => {
        draft[key] = value
      })
    )
    updateError(key, '')
  }

  const clearEntityValue = (key) => {
    setValues(
      produce((draft) => {
        if (draft[key]) {
          delete draft[key]
        }
      })
    )
    updateError(key, '')
  }

  const setPromotionValue = (promotionId, key, value) => {
    setValues(
      produce((draft) => {
        if (!draft.promotions) draft.promotions = {}
        if (!draft.promotions[promotionId]) draft.promotions[promotionId] = {}
        draft.promotions[promotionId][key] = value
      })
    )
    updateError(`promotions.${promotionId}.${key}`, '')
  }

  const setRuleValue = (promotionId, ruleType, value) => {
    setValues(
      produce((draft) => {
        if (!draft.promotions[promotionId].rules[ruleType]) {
          draft.promotions[promotionId].rules[ruleType] = {
            type: ruleType,
            configuration: null,
          }
        }
        draft.promotions[promotionId].rules[ruleType].configuration = value
      })
    )
    if (ruleType === RULE_TYPES.daysInWeekAndTime) {
      Object.keys(errors).forEach((key) => {
        if (key.includes(RULE_TYPES.daysInWeekAndTime)) {
          updateError(key, '')
        }
      })
    }
    updateError(`promotions.${promotionId}.rules.${ruleType}`, '')
  }

  const setActionValue = (promotionId, actionType, value) => {
    setValues(
      produce((draft) => {
        if (!draft.promotions[promotionId].actions[actionType]) {
          draft.promotions[promotionId].actions[actionType] = {
            type: actionType,
            configuration: null,
          }
        }
        draft.promotions[promotionId].actions[actionType].configuration = value
      })
    )
    updateError(`promotions.${promotionId}.actions.${actionType}`, '')
  }

  const clearRule = (promotionId, ruleType) => {
    setValues(
      produce((draft) => {
        if (draft.promotions[promotionId].rules[ruleType]) {
          delete draft.promotions[promotionId].rules[ruleType]
        }
      })
    )
    updateError(`promotions.${promotionId}.rules.${ruleType}`, '')
  }

  const clearAction = (promotionId, actionType) => {
    setValues(
      produce((draft) => {
        if (draft.promotions[promotionId].actions[actionType]) {
          delete draft.promotions[promotionId].actions[actionType]
        }
      })
    )
    updateError(`promotions.${promotionId}.actions.${actionType}`, '')
  }

  return {
    currentStep,
    loading,
    canSave,
    currentEntity,
    currentEntityId,
    values,
    errors,
    globalErrors,
    removeGlobalError,
    addGlobalError,
    setEntityValue,
    clearEntityValue,
    createNewEntity,
    updateExistingEntity,
    incrementStep,
    decrementStep,
    setStep,
    setPromotionValue,
    setRuleValue,
    setActionValue,
    clearRule,
    clearAction,
    savePromotions,
    deleteExistingEntity,
    activateExistingEntity,
    cancelExistingEntity,
    clearGlobalErrors,
    migrateMembershipAutorenewalSettings,
  }
}

export default useWizard
