import React, { useState, useEffect, useRef, useMemo } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { Tooltip } from 'react-tooltip'

import styles from './styles.module.scss'

import { ReactComponent as ArIcon } from '@sweetspot/sweetspot-js/assets/svgs/gps-icon.svg'
import { ReactComponent as ReservedSlot } from '@sweetspot/sweetspot-js/assets/svgs/reserved-slot.svg'
import { ReactComponent as OwnerIcon } from '@sweetspot/sweetspot-js/assets/svgs/owner-slot.svg'
import { ReactComponent as MemberIcon } from '@sweetspot/sweetspot-js/assets/svgs/member-slot.svg'
import { ReactComponent as GuestIcon } from '@sweetspot/sweetspot-js/assets/svgs/guest-slot.svg'
import { ReactComponent as StubIcon } from '@sweetspot/sweetspot-js/assets/svgs/stub-slot.svg'
import { ReactComponent as SwapReverseIcon } from '@sweetspot/sweetspot-js/assets/svgs/swap-reverse-icon.svg'
import { ReactComponent as PartnerIcon } from '@sweetspot/sweetspot-js/assets/svgs/partner-slot.svg'
import { ReactComponent as GolfCartIcon } from '@sweetspot/club-portal-legacy/resources/images/golf-cart-icon2.svg'

import Checkbox from '@sweetspot/sweetspot-js/common/components/FormElements/Checkbox'
import Skeleton from '@sweetspot/sweetspot-js/common/components/SkeletonLoader'
import PlayerName from '../PlayerName'
import Text from '@sweetspot/club-portal-legacy/components/Text'

import { updateArrivalRegistration } from '@sweetspot/sweetspot-js/features/bookings/services/api-platform'

import { to, getFederationId } from '@sweetspot/sweetspot-js/common/functions/utils'
import { priceToLocal } from '@sweetspot/shared/util/functions'

import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'

import {
  getBookingSlotItems,
  getItemGender,
  getMembershipName,
  isBookingConsideredPaid,
  isBookingPartiallyPaid,
  isBookingPartiallyRefunded,
  isPartner,
} from '@sweetspot/sweetspot-js/features/bookings/functions/utils'
import { ReactComponent as CaretDown } from '@sweetspot/sweetspot-js/assets/svgs/caret-down.svg'
import { useSelector } from 'react-redux'

// Free to modify

const BookingBox = ({
  booking,
  className,
  letter,
  onRequestBookingUpdate,
  onRequestOpenBooking,
  onRequestInitiateMoveBooking,
  onRequestCompleteMoveBooking,
  moving,
}) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()

  const [loadingSlots, setLoadingSlots] = useState([])
  const [displayedPlayerInfo, setDisplayedPlayerInfo] = useState([])
  let cartIndex = 0

  const currentClub = useSelector((state) => {
    return state?.golfClub?.list.find((club) => club.id === state?.golfClub?.selectedId)
  })

  const hasCdhNumber = !!currentClub?.cdh_id

  const displayPlayerInfo = (isOpened, uuid) => {
    if (isOpened) {
      setDisplayedPlayerInfo([...displayedPlayerInfo, uuid])
    } else {
      setDisplayedPlayerInfo(displayedPlayerInfo.filter((i) => i !== uuid))
    }
  }

  const carts = useMemo(() => {
    if (!booking || !booking.items) {
      return []
    }

    return booking.items.filter((item) => {
      return item?.product_variant?.product?.code === 'golf_cart'
    })
  }, [booking])

  useEffect(() => {
    setLoadingSlots([])
  }, [booking])

  const bookingSlotItems = useMemo(() => {
    if (booking) {
      return getBookingSlotItems(booking)
    }
    return []
  }, [booking])

  const headerRef = useRef()

  useEffect(() => {
    const handler = () => {
      onRequestOpenBooking(booking?.uuid)
    }

    if (headerRef?.current) {
      headerRef.current.addEventListener('dblclick', handler)
    }
    return () => headerRef?.current?.removeEventListener('dbclick', handler)
  }, [headerRef, onRequestOpenBooking, booking])

  const handleRegisterAr = async (slot, value, skipBookingUpdate = false) => {
    const { uuid } = slot
    setLoadingSlots((prev) => {
      if (!prev.includes(uuid)) {
        return [...prev, uuid]
      }
    })
    if (uuid) {
      const [res, err] = await to(updateArrivalRegistration(uuid, value))
      if (!res || err) {
        addToast(t('sentences.couldNotRegisterArrival'), { appearance: 'error' })
      }
      if (skipBookingUpdate) {
        return
      }
      if (onRequestBookingUpdate) {
        onRequestBookingUpdate(booking.uuid)
      }
    }
  }

  const handleRegisterArAll = async () => {
    let arrivalRegistrationStatus = true
    if (
      bookingSlotItems.filter(({ slot }) => hasAR(slot)).length >
      bookingSlotItems.filter(({ slot }) => !hasAR(slot)).length
    ) {
      // If more are arrival registered than not arrived then unregister arrival on all
      arrivalRegistrationStatus = false
    }

    Promise.all(
      bookingSlotItems.map(({ slot }) => {
        if (hasAssignedPlayer(slot)) return handleRegisterAr(slot, arrivalRegistrationStatus, true)
        return true
      })
    ).then(() => {
      onRequestBookingUpdate(booking.uuid)
    })
  }

  const hasAR = (slot) => {
    return Boolean(slot?.is_arrival_registration)
  }
  const isStub = (slot) => {
    return slot?.type === 'stub_player'
  }
  const isMember = (slot) => {
    return slot?.is_member === true
  }
  const isOwner = (slot) => {
    return slot?.is_owner === true
  }
  const isSweetspot = (slot) => {
    return slot?.some_future_flag === true
  }
  const hasAssignedPlayer = (slot) => {
    const { player, type, stub_player } = slot
    return !(!player && type !== 'stub_player' && !stub_player.name)
  }

  const getBookingPrice = () => {
    if (!booking?.items?.length) return ''

    const getTotal = (state) =>
      booking.items
        .filter((item) => item.state === state)
        .reduce((sum, item) => sum + item.total, 0)

    const total = isBookingPartiallyRefunded(booking)
      ? getTotal('paid')
      : isBookingPartiallyPaid(booking)
      ? getTotal('new')
      : booking.items.reduce((sum, item) => sum + item.total, 0)

    return priceToLocal(total, booking.currency_code, true)
  }

  const getHcp = (slot) => {
    return slot?.player?.hcp || '-'
  }

  const handleMoveBooking = () => {
    if (!moving) {
      onRequestInitiateMoveBooking(booking)
      return
    }
    if (moving) {
      onRequestCompleteMoveBooking({ ...booking, char: letter })
    }
  }

  return (
    <div className={cx(styles.container, className, { [styles.moving]: moving })}>
      <div className={cx(styles.headerRow)} ref={headerRef}>
        {/* <p className={cx(styles.bookingLetter)}>{letter}</p> */}
        <p className={cx(styles.bookingCost)}>
          <span className={'text-sm text-white'}>{getBookingPrice()}</span>
        </p>
        {isBookingConsideredPaid(booking) && !isBookingPartiallyPaid(booking) && (
          <div className="mx-2 rounded-full bg-emerald-500 px-2 text-xs text-white">
            {t('words.paid')}
          </div>
        )}

        {(!isBookingConsideredPaid(booking) || isBookingPartiallyPaid(booking)) && (
          <div className="mx-2 rounded-full bg-red-500 px-2 text-xs text-white">
            {t('words.unpaid')}
          </div>
        )}

        <div
          className={cx(styles.moveContainer, {
            [styles.active]: moving,
          })}
          onClick={handleMoveBooking}
        >
          <SwapReverseIcon className={cx(styles.icon)} />
          <p className={'text-sm text-white'}>{t('words.move')}</p>
        </div>
        <div className={cx(styles.arIconWrapper)}>
          <ArIcon className={cx(styles.arIcon)} onClick={handleRegisterArAll} />
        </div>
      </div>
      {booking.items.map((item) => {
        const { slot } = item
        if (!slot?.uuid) return null

        const loading = loadingSlots?.includes(slot.uuid)
        const playerIsStub = isStub(slot)
        const playerIsMember = isMember(slot)
        const playerIsOwner = isOwner(slot)
        const playerIsSweetspot = isSweetspot(slot)
        const isPartnership = isPartner(booking)

        const isOpened = displayedPlayerInfo.includes(slot.uuid)
        const golfCart = carts[cartIndex]
        cartIndex++

        return (
          <div className={cx(styles.slotRow)} key={slot.id}>
            <div className={cx(styles.slotInfo)}>
              <div
                className={cx(styles.playerInfoToggle, 'fixed-left')}
                onClick={() => displayPlayerInfo(!isOpened, slot.uuid)}
              >
                <CaretDown
                  className={cx(styles.icon, {
                    [styles.open]: isOpened,
                  })}
                />
              </div>
              <div className={styles.name}>
                <PlayerName slot={slot} loading={loading} />
              </div>
              <p className={cx(styles.hcp)}>
                {loading ? <Skeleton width={40}></Skeleton> : getHcp(slot)}
              </p>
              <p className={cx(styles.golfId)}>
                {loading ? (
                  <Skeleton width={60}></Skeleton>
                ) : (
                  getFederationId(hasCdhNumber, slot?.player)
                )}
              </p>
              <div className={cx(styles.typeWrapper)}>
                {hasAssignedPlayer(slot) ? (
                  <React.Fragment>
                    {playerIsOwner && <OwnerIcon className={cx(styles.typeIcon)} />}

                    <div className={`${playerIsOwner ? '' : 'ml-[30px]'} flex`}>
                      {isPartnership && <PartnerIcon className={cx(styles.typeIcon)} />}
                      {!playerIsStub &&
                        !isPartnership &&
                        (playerIsMember ? (
                          <MemberIcon className={cx(styles.typeIcon)} />
                        ) : (
                          <GuestIcon className={cx(styles.typeIcon)} />
                        ))}
                      {playerIsStub && !isPartnership && (
                        <StubIcon className={cx(styles.typeIcon)} />
                      )}
                      {playerIsSweetspot && (
                        <div>
                          <i className="fa-kit fa-ss-icon-solid mr-[10px] text-xl"></i>
                        </div>
                      )}
                    </div>
                    {golfCart && (
                      <div className={styles.golfCartsTooltip}>
                        <div
                          className={styles.golfCarts}
                          data-tooltip-id={`golf_carts_${golfCart?.uuid}`}
                          data-tip
                        >
                          <GolfCartIcon />
                        </div>
                        <Tooltip
                          id={`golf_carts_${golfCart?.uuid}`}
                          place="top"
                          effect="solid"
                          className={styles.copyTooltip}
                        >
                          <Text textId={golfCart?.product_variant?.name} />
                        </Tooltip>
                      </div>
                    )}
                  </React.Fragment>
                ) : (
                  <ReservedSlot className={cx(styles.typeIcon)} />
                )}
              </div>
              <div className={cx(styles.arWrapper)}>
                {hasAssignedPlayer(slot) && (
                  <Checkbox
                    containerClassName={cx(styles.inputWrapperClassName)}
                    value={hasAR(slot)}
                    onChange={(value) => handleRegisterAr(slot, value)}
                  />
                )}
              </div>
            </div>
            {isOpened ? (
              <div className={cx(styles.playerInfo)}>
                <p className={styles.text}>
                  {loading ? (
                    <Skeleton width={180} />
                  ) : (
                    <>
                      {t('words.email')}:{' '}
                      <span className={styles.value}>
                        {playerIsStub
                          ? slot?.stub_player?.email || '-'
                          : slot?.player?.email || '-'}
                      </span>
                    </>
                  )}
                </p>
                <p className={styles.text}>
                  {loading ? (
                    <Skeleton width={200} />
                  ) : (
                    <>
                      {t('words.homeClub')}:{' '}
                      <span className={cx(styles.value)}>
                        {slot?.player?.home_club?.name || '-'}
                      </span>
                    </>
                  )}
                </p>
                <p className={cx(styles.text)}>
                  {loading ? (
                    <Skeleton width={150} />
                  ) : (
                    <>
                      {t('words.gender')}:{' '}
                      <span className={styles.value}>{getItemGender(item)}</span>
                    </>
                  )}
                </p>
                <p className={cx(styles.text)}>
                  {loading ? (
                    <Skeleton width={170} />
                  ) : (
                    <>
                      {t('words.membership')}:{' '}
                      <span className={styles.value}>{getMembershipName(item)}</span>
                    </>
                  )}
                </p>
              </div>
            ) : null}
          </div>
        )
      })}
      {carts && carts.length > 0 && (
        <div className=" flex w-full bg-neutral-100 px-[15px] py-2">
          <p className="text-sm font-semibold">
            {t('words.golfCarts')}:
            <span className="font-normal">
              {carts.length > 0 &&
                carts.map((cart) => ` 1 x ${cart?.product_variant?.name}`).join(', ')}
            </span>
          </p>
        </div>
      )}
    </div>
  )
}

BookingBox.propTypes = {
  booking: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        slot: PropTypes.shape({}),
      })
    ),
    total: PropTypes.number,
    uuid: PropTypes.string,
    partnership: PropTypes.object,
  }),
  loaders: PropTypes.shape({}),
  className: PropTypes.string,
  letter: PropTypes.string,
  onRequestBookingUpdate: PropTypes.func,
  onRequestOpenBooking: PropTypes.func,
  onRequestInitiateMoveBooking: PropTypes.func,
  onRequestCompleteMoveBooking: PropTypes.func,
  moving: PropTypes.bool,
}

BookingBox.defaultProps = {
  onRequestBookingUpdate: () => {},
  onRequestOpenBooking: () => {},
}

export default BookingBox
