import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import { useQueryClient } from 'react-query'
import { CLUB_QUERIES } from '@sweetspot/shared/util/constants'
import {
  IUniversalIdentifier,
  MembershipCardPaymentSource,
  MembershipCardPayment,
} from '@sweetspot/shared/types'

import AddMembership from '../AddMembership'
import PaymentPlanTable from '../PaymentPlan'
import ButtonDropdown from '@sweetspot/club-portal-legacy/components/ButtonDropdown'
import ConfirmWindow from '@sweetspot/club-portal-legacy/Partials/Dialogs/ConfirmWindow'
import ConfirmModal from '@sweetspot/club-portal-legacy/components/ConfirmModal'
import DropdownSelect from '@sweetspot/club-portal-legacy/components/DropdownSelect'
import Text from '@sweetspot/club-portal-legacy/components/Text'

import styles from './style.module.scss'

import MembershipCardComponent from '@sweetspot/sweetspot-js/features/memberships/containers/MembershipCard'
import {
  getFirstMatchingRole,
  hasAccess,
} from '@sweetspot/sweetspot-js/features/userAccess/utils/utils'
import { ACCESS_KEYS } from '@sweetspot/sweetspot-js/features/userAccess/constants/accessTable'
import { Membership, Player, MembershipCard } from '@sweetspot/shared/types'
import { useSelector } from 'react-redux'
import { RootState } from '@sweetspot/club-portal-legacy/store/types'
import {
  getAvailableActions,
  sortMemberships,
} from '@sweetspot/club-portal-legacy/modals/PlayerCard/utils/membership'
import { MembershipActions } from '@sweetspot/club-portal-legacy/modals/PlayerCard/types'
import { usePlayerCardContext } from '@sweetspot/club-portal/data-access/context-providers'
import { useMembershipCardPayments } from '@sweetspot/sweetspot-js/common/react-query/hooks/useMembershipCard'
import { STATUSES } from '@sweetspot/sweetspot-js/common/components/StatusPill'
import {
  InputSelect,
  InputSelectContent,
  InputSelectItem,
  InputSelectContentView,
  InputSelectTrigger,
  InputContainer,
  InputSelectStatefulInput,
  InputSelectTrailingContainer,
  InputBase,
  ListItem,
  ListItemMainContent,
  ListItemParagraph,
  Check,
} from '@sweetspot/scramble-ds'
import { useTranslation } from 'react-i18next'

const SEND_PAYMENT_LINK = 1
const MARK_AS_PAID = 2
const REMOVE_MEMBERSHIP = 3
const ADD_MEMBERSHIP = 4
const CANCEL_OR_REFUND = 5
const LOCK_UNLOCK_MEMBERSHIP = 6

const MEMBERSHIP_AWAITING_PAYMENT = 'awaiting_payment'
const MEMBERSHIP_INSTALLMENT_LOAN = 'installment_loan'

const ReactQueryInvalidator = ({
  memberships,
  membershipCardId,
}: {
  memberships: MembershipCard[]
  membershipCardId: number
}) => {
  const queryClient = useQueryClient()

  useEffect(() => {
    if (membershipCardId && memberships) {
      queryClient.invalidateQueries([CLUB_QUERIES.MEMBERSHIP_CARDS, membershipCardId])
    }
  }, [membershipCardId, memberships, queryClient])

  return null
}

export type MembershipHandlerProps = {
  clubId: number
  isLoading: boolean
  player: Player | null
  membershipCards: MembershipCard[]
  membershipList?: Membership[]
  markAsPaid: (id: number) => void
  removeMembership: (id: number) => void
  sendPaymentLink: (id: number) => void
  cancelMembershipAutoRenewal: (id: number) => void
  enableMembershipAutoRenewal: (id: number) => void
  unMarkAsPaid: (id: number, UUID: string, refund: boolean, cancelUpcomingPayments: boolean) => void
  refundMembershipCard: (id: number, UUID: string, isThereAnyUpcomingPayments?: boolean) => void
  handleCancelUpcomingPayments: (id: number) => void
  onAddMembership: (activeMembership?: Membership | null) => void
  handleLockOrUnlockMembership: (uuid: string, is_locked: boolean) => void
}

export interface MembershipPaymentObjectProps extends IUniversalIdentifier {
  amount: { value: number; currency: string }
  state: 'paid' | 'canceled' | 'awaiting_payment'
  type: 'prepaid' | 'installment_loan'
  paid_at: string | null
}

export type MembershipPaymentsArray = MembershipPaymentObjectProps[]

const MembershipHandler: React.FC<MembershipHandlerProps> = ({
  membershipCards,
  player,
  membershipList = [],
  markAsPaid,
  removeMembership,
  sendPaymentLink,
  unMarkAsPaid,
  isLoading,
  onAddMembership,
  refundMembershipCard,
  cancelMembershipAutoRenewal,
  enableMembershipAutoRenewal,
  handleCancelUpcomingPayments,
  handleLockOrUnlockMembership,
}) => {
  const { t } = useTranslation()
  const [activeId, setActiveId] = useState<number>(-1)
  const [activeMembership, setActiveMembership] = useState<Membership | undefined | null>(null)
  const [confirmCancelOrRefund, setConfirmCancelOrRefund] = useState<boolean>(false)
  const { dialogType, setDialogType } = usePlayerCardContext()
  const [cancelOrRefundState, setCancelOrRefundState] = useState({
    cancelMembership: false,
    refundPrevPayments: false,
    cancelUpcomingPayments: false,
  })
  const role = useSelector((state: RootState) => getFirstMatchingRole(state.auth.roles))
  const membershipCardIDRef = useRef<number | null>(null)

  useEffect(() => {
    if (membershipCardIDRef?.current) {
      setActiveId(membershipCardIDRef?.current)
      membershipCardIDRef.current = null
    } else if (membershipCards?.length) {
      setActiveId(membershipCards[0].id)
    }
  }, [membershipCardIDRef, membershipCards])
  const membership = useMemo(
    () => membershipCards.find((item) => item.id === activeId),
    [activeId, membershipCards]
  )

  const {
    data: paymentsData,
    refetch: refetchPaymentsData,
  }: { data: MembershipCardPayment[] | undefined } = useMembershipCardPayments(membership?.uuid)

  const isOnlinePaymentRefunded =
    paymentsData &&
    paymentsData?.length > 0 &&
    (paymentsData[0]?.state === 'refunded' || paymentsData[0]?.state === 'refund_initiated')

  const isMembershipLocked = membership?.is_locked
  const onlinePayment = membership?.payment_source === MembershipCardPaymentSource.ONLINE
  const isThereUnpaidUpcomingPayments = paymentsData?.some(
    (paymentObj) =>
      paymentObj?.state === MEMBERSHIP_AWAITING_PAYMENT &&
      paymentObj?.type === MEMBERSHIP_INSTALLMENT_LOAN
  )
  const getActions = useCallback(
    (membership: MembershipCard) =>
      getAvailableActions(
        membership,
        membershipList,
        role?.value || '',
        isThereUnpaidUpcomingPayments,
        paymentsData,
        isMembershipLocked,
        player?.playRight
      ),
    [
      paymentsData,
      membershipList,
      player?.playRight,
      role?.value,
      isThereUnpaidUpcomingPayments,
      isMembershipLocked,
    ]
  )

  const actionHandlers: Record<number, (membershipId?: number) => void> = useMemo(
    () => ({
      [MembershipActions.SEND_PAYMENT_LINK]: () =>
        setDialogType(MembershipActions.SEND_PAYMENT_LINK),
      [MembershipActions.MARK_AS_PAID]: () => setDialogType(MembershipActions.MARK_AS_PAID),
      [MembershipActions.REMOVE_MEMBERSHIP]: () =>
        setDialogType(MembershipActions.REMOVE_MEMBERSHIP),
      [MembershipActions.ADD_MEMBERSHIP]: (membershipId) => {
        setDialogType(ADD_MEMBERSHIP)
        setActiveMembership(membershipList.find((item) => item.id === membershipId))
      },
      [MembershipActions.CANCEL_OR_REFUND]: () => setDialogType(MembershipActions.CANCEL_OR_REFUND),
      [MembershipActions.LOCK_UNLOCK_MEMBERSHIP]: () =>
        setDialogType(MembershipActions.LOCK_UNLOCK_MEMBERSHIP),
    }),
    [membershipList, setDialogType]
  )

  const handleClickActions = useCallback(
    (id: number, membershipId: number) => {
      refetchPaymentsData()
      const handler = actionHandlers[id]
      if (handler) handler(membershipId)
    },
    [actionHandlers, refetchPaymentsData]
  )
  const handleConfirm = useCallback(() => {
    if (!membership) return
    switch (dialogType) {
      case SEND_PAYMENT_LINK:
        if (membership.order) sendPaymentLink(membership.order.token)
        break
      case MARK_AS_PAID:
        membershipCardIDRef.current = membership?.id
        markAsPaid(membership.id)
        break
      case REMOVE_MEMBERSHIP:
        removeMembership(membership.id)
        break
      case LOCK_UNLOCK_MEMBERSHIP:
        membershipCardIDRef.current = membership?.id
        handleLockOrUnlockMembership(membership?.id, membership?.is_locked)
        break
      default:
        break
    }
    setDialogType(null)
  }, [dialogType, markAsPaid, membership, removeMembership, sendPaymentLink, setDialogType])

  const handleCancelOrRefundConfirmAction = useCallback(() => {
    if (!membership) return
    const { cancelMembership, refundPrevPayments, cancelUpcomingPayments } = cancelOrRefundState
    membershipCardIDRef.current = membership?.id

    if (cancelMembership) {
      unMarkAsPaid(membership?.id, membership?.uuid, refundPrevPayments, cancelUpcomingPayments)
    } else if (refundPrevPayments || cancelUpcomingPayments) {
      // Only refund
      if (refundPrevPayments && !cancelUpcomingPayments) {
        refundMembershipCard(membership.id, membership?.uuid, false)
      }
      // Only cancel upcoming payments
      if (!refundPrevPayments && cancelUpcomingPayments) {
        handleCancelUpcomingPayments(membership?.id)
      }
      // Refund and cancel upcoming payments
      if (refundPrevPayments && cancelUpcomingPayments) {
        refundMembershipCard(membership.id, membership?.uuid, true)
      }
    }

    setCancelOrRefundState({
      cancelMembership: false,
      refundPrevPayments: false,
      cancelUpcomingPayments: false,
    })
    setDialogType(null)
    setConfirmCancelOrRefund(false)
    refetchPaymentsData()
  }, [cancelOrRefundState, membership, unMarkAsPaid, refundMembershipCard, refetchPaymentsData])

  const cancelOrRefundConfirmButtonState = useMemo(() => {
    const { cancelMembership, refundPrevPayments, cancelUpcomingPayments } = cancelOrRefundState
    if (confirmCancelOrRefund && (cancelMembership || refundPrevPayments || cancelUpcomingPayments))
      return false

    return true
  }, [cancelOrRefundState, confirmCancelOrRefund])

  const renderCancelOrRefundContent = () => {
    const { cancelMembership, refundPrevPayments, cancelUpcomingPayments } = cancelOrRefundState
    const isPaid = membership?.is_paid || membership?.status === 'old'
    const isCancelled = membership?.status === 'canceled'

    return (
      <div>
        <div className="mb-2">{t('players.editMembershipSubTitle')}</div>
        <Check
          onCheckedChange={(val) =>
            setCancelOrRefundState((previousState) => ({
              ...previousState,
              cancelMembership: val,
            }))
          }
          disabled={!isPaid || isCancelled}
          checked={cancelMembership}
          label={t('players.unmarkMembership')}
        />
        <Check
          onCheckedChange={(val) =>
            setCancelOrRefundState((previousState) => ({
              ...previousState,
              refundPrevPayments: val,
            }))
          }
          disabled={!onlinePayment || (isOnlinePaymentRefunded && onlinePayment)}
          checked={refundPrevPayments}
          label={t('players.refundCompletedPayments')}
        />
        <Check
          onCheckedChange={(val) =>
            setCancelOrRefundState((previousState) => ({
              ...previousState,
              cancelUpcomingPayments: val,
            }))
          }
          disabled={!isThereUnpaidUpcomingPayments}
          checked={cancelUpcomingPayments}
          label={t('players.cancelUpcomingPayments')}
        />

        <div className="mb-2 mt-7">
          <Check
            onCheckedChange={(val) => setConfirmCancelOrRefund(val)}
            checked={confirmCancelOrRefund}
            label={t('players.actionConfirmation')}
          />
        </div>
      </div>
    )
  }

  const renderDialog = () => {
    switch (dialogType) {
      case SEND_PAYMENT_LINK:
        return (
          <ConfirmWindow
            textArray={[
              'players.sendPaymentLink',
              'players.to',
              `@${player?.first_name} ${player?.last_name}`,
              'players.for',
              `@${membership?.membership.name}`,
              '@?',
            ]}
            isInline
            cancelText="cancel"
            confirmText="confirm"
            onCancel={() => setDialogType(null)}
            onConfirm={handleConfirm}
          />
        )
      case MARK_AS_PAID:
        return (
          <ConfirmWindow
            textArray={[
              'players.mark',
              `@${membership?.membership.name}`,
              'players.asPaid',
              'players.for',
              `@${player?.first_name} ${player?.last_name}`,
              '@?',
            ]}
            isInline
            cancelText="cancel"
            confirmText="confirm"
            onCancel={() => setDialogType(null)}
            onConfirm={handleConfirm}
          />
        )
      case REMOVE_MEMBERSHIP:
        return (
          <ConfirmWindow
            textArray={[
              'membership.unassignModal',
              `@${membership?.membership.name}`,
              'players.from',
              `@${player?.first_name} ${player?.last_name}`,
              '@?',
            ]}
            isInline
            cancelText="cancel"
            confirmText="confirm"
            onCancel={() => setDialogType(null)}
            onConfirm={handleConfirm}
          />
        )
      case LOCK_UNLOCK_MEMBERSHIP:
        return (
          <ConfirmWindow
            textArray={
              membership?.is_locked
                ? ['membership.unlockmembershipModal', `@${membership?.membership.name}`]
                : ['membership.lockMembershipModal', `@${membership?.membership.name}`]
            }
            isInline
            switchBtnPosition
            cancelText="cancel"
            confirmText="confirm"
            onCancel={() => setDialogType(null)}
            onConfirm={handleConfirm}
          />
        )
      case ADD_MEMBERSHIP:
        return (
          <AddMembership
            disabled={isLoading}
            playerName={`${player?.first_name} ${player?.last_name}`}
            membership={activeMembership}
            onConfirm={async () => {
              try {
                const result = await onAddMembership(activeMembership)
                membershipCardIDRef.current = result.id
              } catch (err) {
                console.error(err)
              }
            }}
            onCancel={() => {
              setDialogType(null)
              setActiveMembership(null)
            }}
          />
        )
      case CANCEL_OR_REFUND:
        return (
          <ConfirmModal
            headerText="players.editMembership"
            children={renderCancelOrRefundContent()}
            cancelText="cancel"
            confirmText="players.actionButton"
            disableConfirmButton={cancelOrRefundConfirmButtonState}
            onCancel={() => {
              setDialogType(null)
              setConfirmCancelOrRefund(false)
              setCancelOrRefundState({
                cancelMembership: false,
                refundPrevPayments: false,
                cancelUpcomingPayments: false,
              })
            }}
            onConfirm={handleCancelOrRefundConfirmAction}
          />
        )
      default:
        return
    }
  }

  const renderNoMembership = () => {
    const onlyActiveMemberships = membershipList.filter((membership) => membership.is_active)
    const memberships = onlyActiveMemberships
      .map((item) => ({
        id: item.id,
        name: item.name,
        disabled: !player?.playRight && item.play_right_only,
      }))
      .sort((a, b) => {
        return a.name > b.name ? 1 : a.name < b.name ? -1 : 0
      })

    return (
      <div className={styles.noMembership}>
        <div className={styles.noMembershipIcon}>
          <i className="fa-regular fa-address-card" />
        </div>
        <h1>
          <Text textId="players.noMembership" />
        </h1>
        <h6>
          <Text textId="players.noMembershipDesc" />
        </h6>
        <div className={styles.dropdownContainer}>
          {hasAccess(ACCESS_KEYS.FEATURES.PLAYER_CARD.MEMBERSHIP.ADD, role?.value) && (
            <InputSelect
              disabled={!membershipList.length}
              onValueChange={(id: number) => handleClickActions(ADD_MEMBERSHIP, id)}
            >
              <InputBase>
                <InputSelectTrigger asChild>
                  <InputContainer className="ring-border-stroke-subtle">
                    <InputSelectStatefulInput
                      className="border-border-stroke-subtle w-[300px] py-0"
                      value={t('players.addToMembership')}
                    />
                    <InputSelectTrailingContainer />
                  </InputContainer>
                </InputSelectTrigger>
              </InputBase>
              <InputSelectContent className="max-h-56">
                <InputSelectContentView>
                  {memberships.map((membership) => {
                    return (
                      <InputSelectItem value={membership?.id}>
                        <ListItem className="ml-2 h-full justify-center gap-2">
                          <ListItemMainContent className="justify-center">
                            <ListItemParagraph className="text-content-base">
                              {membership?.name}
                            </ListItemParagraph>
                          </ListItemMainContent>
                        </ListItem>
                      </InputSelectItem>
                    )
                  })}
                </InputSelectContentView>
              </InputSelectContent>
            </InputSelect>
          )}
        </div>
        {renderDialog()}
      </div>
    )
  }

  const sortedMemberships = useMemo(() => sortMemberships(membershipCards || []), [membershipCards])

  const filteredMemberships = sortedMemberships.filter(
    (membership) => membership?.state !== MEMBERSHIP_AWAITING_PAYMENT
  )

  const filteredMembershipsDropdownList = useMemo(() => {
    return sortedMemberships
      .filter((membership) => membership?.state !== MEMBERSHIP_AWAITING_PAYMENT)
      .sort((a, b) => {
        if (a.state === 'new') return -1
        if (b.state === 'new') return 1
        if (!b?.starts_at) return 1
        return new Date(b?.starts_at).getTime() - new Date(a.starts_at).getTime()
      })
      .map((membership) => {
        let membershipStatus = membership?.status
        if (membership?.state === 'new' && !['expired', 'canceled'].includes(membership?.status)) {
          membershipStatus = STATUSES.NEW
        } else if (membership?.state === 'paid' && membership?.status === 'active') {
          membershipStatus = STATUSES.ACTIVE
        } else if (membership?.state === 'paid' && membership?.status === 'upcoming') {
          membershipStatus = STATUSES.UPCOMING
        }

        return {
          id: membership.id,
          value: membership.membership.uuid,
          name: `${membership.membership.name} (ID: ${membership.id})`,
          startDate: membership.starts_at,
          desc: {
            text: `membership.status.${membershipStatus}`,
            isActive: false,
          },
        }
      })
  }, [sortedMemberships, membership])

  if (!filteredMemberships || !filteredMemberships?.length) return renderNoMembership()

  return (
    <div className={styles.content}>
      {renderDialog()}
      <div className={styles.header}>
        <DropdownSelect
          blueStyle
          hideSelf
          readOnly
          showBorder
          className="bold"
          noOtherOption="players.noOtherMembership"
          width="100%"
          withoutFocusedStyle={true}
          initialId={membership?.id}
          selectedId={activeId}
          values={filteredMembershipsDropdownList}
          onSelect={(id) => setActiveId(id)}
        />
        {membership && (
          <ButtonDropdown
            text="players.options"
            values={getActions(membership)}
            onClick={handleClickActions}
            fromRight={true}
          />
        )}
      </div>

      <div className={styles.body}>
        {membership && (
          <>
            <ReactQueryInvalidator
              memberships={filteredMemberships}
              membershipCardId={membership.id}
            />
            <MembershipCardComponent
              membershipCardId={membership.id}
              paymentPlanComponent={(paymentsData) => (
                <PaymentPlanTable paymentsData={paymentsData} />
              )}
              paymentsData={paymentsData}
              cancelMembershipAutoRenewal={cancelMembershipAutoRenewal}
              enableMembershipAutoRenewal={enableMembershipAutoRenewal}
              role={role}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default MembershipHandler
