import React, { useLayoutEffect, useRef, useState } from 'react'
import useOnClickOutside from '@sweetspot/sweetspot-js/common/hooks/useOnClickOutside'
import Portal from '@sweetspot/sweetspot-js/common/components/Portal'
import cx from 'classnames'
import styles from './style.module.scss'
import PropTypes from 'prop-types'

const CalendarBox = ({
  visible,
  onClosePreview,
  containerRef,
  CalendarComponent,
  helperText,
  mobileResponsive,
}) => {
  const boxRef = useRef(null)
  const [position, setPosition] = useState({ top: 0, left: 0 })
  const [isMobile, setIsMobile] = useState(false)

  const [animation, setAnimation] = useState(isMobile ? 'in' : null)

  useOnClickOutside(boxRef, () => {
    if (mobileResponsive && isMobile) {
      setAnimation('out')
      setTimeout(() => {
        onClosePreview()
      }, 700)
      return
    }

    onClosePreview()
  })

  useLayoutEffect(() => {
    if (!mobileResponsive) return
    const updateIsMobile = () => {
      const isMobile = window.innerWidth <= 1024
      setIsMobile(isMobile)
      setAnimation(isMobile ? 'in' : null)
    }
    window.addEventListener('resize', updateIsMobile)
    updateIsMobile()

    return () => {
      window.removeEventListener('resize', updateIsMobile)
    }
  }, [mobileResponsive])

  useLayoutEffect(() => {
    if (!containerRef.current || !boxRef.current || !visible) return

    const parentRect = containerRef.current.getBoundingClientRect()
    const boxRect = boxRef.current.getBoundingClientRect()
    const viewportWidth = window.innerWidth
    const viewportHeight = window.innerHeight

    if (mobileResponsive && isMobile) {
      setPosition({ top: viewportHeight, left: 0 }) // Initially position it at the bottom for animation
    } else {
      const containerCenterX = parentRect.left + parentRect.width / 2
      let newLeft = containerCenterX - boxRect.width / 2

      newLeft = Math.max(newLeft, 0)
      if (newLeft + boxRect.width > viewportWidth) {
        newLeft = viewportWidth - boxRect.width
      }

      let newTop = parentRect.bottom + window.scrollY
      if (newTop + boxRect.height > viewportHeight + window.scrollY) {
        newTop = parentRect.top + window.scrollY - boxRect.height
      }

      setPosition({ top: newTop, left: newLeft })
    }
  }, [
    containerRef.current,
    boxRef.current,
    visible,
    window.innerWidth,
    window.innerHeight,
    isMobile,
    containerRef,
    mobileResponsive,
  ])

  return (
    <div
      className={cx(
        mobileResponsive && styles.mobileWrapper,
        !isMobile && 'bg-black/25',
        mobileResponsive && isMobile && animation === 'in' && 'bg-black/80',
        mobileResponsive && isMobile && animation === 'out' && 'bg-transparent'
      )}
    >
      <Portal id={'calendar-box'}>
        <div
          className={cx(
            styles.container,
            visible && styles.visible,
            mobileResponsive && styles.mobileContainer,
            mobileResponsive && animation === 'in' && styles.animateIn,
            mobileResponsive && animation === 'out' && styles.animateOut
          )}
          style={
            mobileResponsive && isMobile
              ? { position: 'fixed', width: '100%', bottom: 0, left: 0, zIndex: 100 }
              : { position: 'fixed', ...position, zIndex: 100 }
          }
          onClick={(e) => e.stopPropagation()}
          ref={boxRef}
        >
          {CalendarComponent && <CalendarComponent.component {...CalendarComponent.props} />}
          {!!helperText && (
            <div className="flex justify-center px-2 py-6 text-center">
              <span className="text-xs text-blue-500">{helperText}</span>
            </div>
          )}
        </div>
      </Portal>
    </div>
  )
}

export default CalendarBox

CalendarBox.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClosePreview: PropTypes.func.isRequired,
  containerRef: PropTypes.object,
  CalendarComponent: PropTypes.object,
}

CalendarBox.defaultProps = {
  containerRef: null,
  CalendarComponent: null,
}
