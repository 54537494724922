import React, { useState, useEffect, useCallback } from 'react'
import cx from 'classnames'

import { Player } from '@sweetspot/shared/types'
import Button from '@sweetspot/sweetspot-js/common/components/Button'
import ConfirmPopup from '@sweetspot/sweetspot-js/common/components/ConfirmPopup'
import DropdownSelect from '@sweetspot/club-portal-legacy/components/DropdownSelect'
import Text from '@sweetspot/club-portal-legacy/components/Text'
import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'

import WarningIcon from '@sweetspot/club-portal-legacy/resources/images/warning-icon.png'

import style from './style.module.scss'
import MiniCalendar from '@sweetspot/club-portal-legacy/components/MiniCalendar'
import { useTranslation } from 'react-i18next'
import FieldRenderer from '@sweetspot/club-portal-legacy/modals/PlayerCard/components/Information/FieldRenderer'
import Notes from '@sweetspot/club-portal-legacy/modals/PlayerCard/components/Information/Notes'
import { InformationField } from '@sweetspot/club-portal-legacy/modals/PlayerCard/types'
import usePlayerInformationLogic from '@sweetspot/club-portal-legacy/modals/PlayerCard/hooks/usePlayerInformationLogic'

const switchGolfIdToCdhNumber = (informationFields, isUkUser) => {
  return informationFields.map((field) => {
    if (field.text === 'golfId' && isUkUser) {
      return { ...field, text: 'cdhNumber' }
    } else {
      return field
    }
  })
}

const informationFields: InformationField[] = [
  { id: 1, text: 'firstName', value: 'first_name', isActive: true },
  { id: 2, text: 'lastName', value: 'last_name', isActive: true },
  { id: 3, text: 'settings.playerCard.birthDate', value: 'birthday', isActive: true },
  { id: 4, text: 'settings.playerCard.gender', value: 'gender', isActive: true },
  { id: 5, text: 'golfId', value: 'golf_id', disabled: true, isActive: true },
  { id: 6, text: 'players.federation', value: 'federation', disabled: true, isActive: true },
  { id: 7, text: 'players.hcp', value: 'hcp', disabled: true, isActive: true },
  { id: 8, text: 'playRight', value: 'playRight', isActive: true },
  { id: 9, text: 'players.ssAccount', value: 'is_registered', disabled: true, isActive: true },
  { id: 10, text: 'settings.homeClub.title', value: 'homeClub', disabled: true, isActive: true },
]

const sguFields: InformationField[] = [
  {
    id: 12,
    text: 'players.isProfessional',
    value: 'is_professional',
    disabled: true,
    isActive: true,
  },
  { id: 13, text: 'players.memberType', value: 'memberType', disabled: true, isActive: true },
  {
    id: 14,
    text: 'players.membershipCategory',
    value: 'club_membership_category',
    disabled: true,
    isActive: true,
  },
  {
    id: 15,
    text: 'players.membershipType',
    value: 'membership_type',
    disabled: true,
    isActive: true,
  },
]

const contactDetails: InformationField[] = [
  { id: 16, text: 'communication.email', value: 'email', isActive: true },
  { id: 17, text: 'phone', value: 'phone', isActive: true },
  { id: 18, text: 'players.address', value: 'address', isActive: true },
  { id: 19, text: 'players.zipCode', value: 'zip_code', isActive: true },
  { id: 20, text: 'players.city', value: 'city', isActive: true },
  { id: 21, text: 'players.country', value: 'country', isActive: true },
]

const guardianInformation: InformationField[] = [
  { id: 17, text: 'firstName', value: 'first_name', disabled: true, isActive: true },
  { id: 18, text: 'lastName', value: 'last_name', disabled: true, isActive: true },
  { id: 19, text: 'communication.email', value: 'email', disabled: true },
  { id: 20, text: 'phone', value: 'phone', disabled: true, isActive: true },
  { id: 21, text: 'words.federationNumber', value: 'golf_id', disabled: true, isActive: true },
  { id: 22, text: 'Personal number', value: 'personal_number', disabled: true, isActive: true },
]

type Props = {
  isLoading: boolean
  playerInfo: Player
  onUpdate: (payload: Player) => void
  onChangePlayRight: (playRight: boolean) => void
  isUkUser: boolean
}

const Information: React.FC<Props> = ({
  isLoading,
  playerInfo,
  onUpdate,
  onChangePlayRight,
  isUkUser,
}) => {
  const [activeField, setActiveField] = useState<number>(0)
  const [form, setForm] = useState<Player>(playerInfo)

  const [showEmailConfirm, setEmailConfirm] = useState<boolean>(false)
  const [showBDayConfirm, setBDayConfirm] = useState<boolean>(false)
  const { t } = useTranslation()
  useEffect(() => {
    setForm(playerInfo)
  }, [playerInfo])

  const handleCancel = useCallback(() => {
    setActiveField(0)
    setForm(playerInfo)
  }, [playerInfo])

  const handleKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (!!activeField && e?.keyCode === 27) {
        e.stopPropagation() // Prevent modal to be closed
        handleCancel()
      }
    },
    [activeField, handleCancel]
  )

  // Add event listeners
  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress, true)

    return () => {
      window.removeEventListener('keydown', handleKeyPress, true)
    }
  }, [handleKeyPress])

  const {
    options,
    memberTypeOptions,
    membershipCategoryOptions,
    membershipTypeOptions,
    genderOptions,
    countries,
    isValidEmail,
    isValidPhoneNumber,
    isValidDateString,
    getErrorMessage,
  } = usePlayerInformationLogic()

  const handleChange = useCallback(
    (name: string, value: string | boolean) => {
      setForm({ ...form, [name]: value })
    },
    [form]
  )

  const handleSave = useCallback(
    async (countryCode = '', gender: string | null = null, memberType: string | null = null) => {
      const payload = {
        id: form.id,
        first_name: form.first_name,
        last_name: form.last_name,
        email: form.email,
        phone: form.phone,
        address: form.address,
        zip_code: form.zip_code,
        city: form.city,
        country: countryCode || form.country,
        birthday: form.birthday || null,
        gender: gender || form.gender,
        member_category: memberType || form.memberType,
        is_professional: form.is_professional || false,
        club_membership_category: form.club_membership_category || 'annual_duration',
        membership_type: form.membership_type || null,
      }

      const payloadData = { ...payload }
      Object.keys(payloadData).map((item) => {
        if (payloadData[item] === '') {
          payloadData[item] = null
        }
      })

      await setActiveField(0)
      onUpdate(payloadData as Player)
    },
    [
      form.address,
      form.birthday,
      form.city,
      form.country,
      form.email,
      form.first_name,
      form.gender,
      form.id,
      form.last_name,
      form.phone,
      form.zip_code,
      onUpdate,
    ]
  )

  const handleSelect = useCallback(
    (value: string) => {
      setActiveField(0)
      onChangePlayRight(!!value)
    },
    [onChangePlayRight]
  )

  const handleChangeGender = useCallback(
    (value: string) => {
      setForm({ ...form, gender: value })
      handleSave('', value)
    },
    [form, handleSave]
  )

  const handleChangeMemberType = useCallback(
    (value: string) => {
      setForm({ ...form, memberType: value })
      handleSave('', null, value)
    },
    [form, handleSave]
  )

  const handleChangeMembershipType = useCallback(
    (value: string) => {
      setForm({ ...form, membership_type: value })
      handleSave()
    },
    [form, handleSave]
  )

  const handleChangeMembershipCategory = useCallback(
    (value: string) => {
      setForm({ ...form, club_membership_category: value })
      handleSave()
    },
    [form, handleSave]
  )

  const handlePressEnter = useCallback(
    (name: keyof Player) => {
      if (form[name] === playerInfo[name]) return
      if (name === 'email' && !isValidEmail(form[name])) return
      if (name === 'phone' && !isValidPhoneNumber(form[name])) return

      if (name === 'email') setEmailConfirm(true)
      if (name === 'birthday') setBDayConfirm(true)
      else handleSave()
    },
    [form, handleSave, isValidEmail, isValidPhoneNumber, playerInfo]
  )

  const handleClickSave = useCallback(
    (name: string) => {
      if (name === 'email') return setEmailConfirm(true)
      if (name === 'birthday') return setBDayConfirm(true)
      handleSave()
    },
    [handleSave]
  )

  const renderValueEdit = useCallback(
    (name: keyof Player) => {
      const country = countries.find((country) => country.code === playerInfo.country)
      switch (name) {
        case 'country':
          return (
            <div className={cx(style.country, { [style.disabled]: isLoading })}>
              <Button
                loaderStyle="pulse"
                size="medium"
                theme="default-outline"
                isSearchable
                dropdownDefaultOpen
                dropdownStyle="simple"
                disabled={isLoading}
                dropdownValue={country?.label || '-'}
                dropdownOptions={countries}
                position="top"
                dropdownOptionOnClick={(val) => handleSave(val.code)}
              />
            </div>
          )
        case 'is_registered':
        case 'playRight':
          return (
            <DropdownSelect
              readOnly
              hideSelf
              width="80px"
              showBorder
              values={options}
              initialId={form[name] ? 1 : 0}
              selectedId={form[name] ? 1 : 0}
              onSelect={handleSelect}
            />
          )
        case 'birthday':
          return (
            <div className={style.bDay}>
              <TextInputField
                type="calendar"
                value={form[name] || ''}
                calendarSettings={{ selectedDate: form.birthday, endDate: new Date() }}
                onChange={(val) => handleChange(name, val)}
                CalendarComponent={MiniCalendar}
              />

              <div className={style.btnGroup}>
                <button className="system-button primary-outline" onClick={handleCancel}>
                  {t('cancel')}
                </button>

                <button
                  className="system-button primary"
                  disabled={
                    form[name] === playerInfo[name] ||
                    (!!form[name] && !isValidDateString(form[name]))
                  }
                  onClick={() => handleClickSave(name)}
                >
                  {t('save')}
                </button>
              </div>
            </div>
          )
        case 'gender':
          return (
            <DropdownSelect
              readOnly
              hideSelf
              showBorder
              placeholder="-"
              values={genderOptions}
              initialId={form[name]}
              selectedId={form[name]}
              onSelect={handleChangeGender}
            />
          )
        case 'memberType':
          return (
            <DropdownSelect
              readOnly
              hideSelf
              showBorder
              placeholder="-"
              values={memberTypeOptions}
              initialId={form[name]}
              selectedId={form[name]}
              onSelect={handleChangeMemberType}
            />
          )
        case 'is_professional':
          return (
            <DropdownSelect
              readOnly
              hideSelf
              width="80px"
              showBorder
              values={options}
              initialId={form[name] ? 1 : 0}
              selectedId={form[name] ? 1 : 0}
              onSelect={(val) => handleChange(name, val === 1)}
            />
          )
        case 'club_membership_category':
          return (
            <DropdownSelect
              readOnly
              hideSelf
              showBorder
              placeholder="-"
              values={membershipCategoryOptions}
              initialId={form[name]}
              selectedId={form[name]}
              onSelect={handleChangeMembershipCategory}
            />
          )
        case 'membership_type':
          return (
            <DropdownSelect
              readOnly
              hideSelf
              showBorder
              placeholder="-"
              values={membershipTypeOptions}
              initialId={form[name]}
              selectedId={form[name]}
              onSelect={handleChangeMembershipType}
            />
          )
        default:
          return (
            <>
              <TextInputField
                inputProps={{ autoFocus: true }}
                value={form[name] || ''}
                error={getErrorMessage(name, form)}
                onEnter={() => handlePressEnter(name)}
                onChange={(val) => handleChange(name, val)}
              />

              <div className={style.btnGroup}>
                <button className="system-button primary-outline" onClick={handleCancel}>
                  {t('cancel')}
                </button>

                <button
                  className="system-button primary"
                  disabled={
                    form[name] === playerInfo[name] ||
                    (name === 'email' && !isValidEmail(form[name])) ||
                    (name === 'phone' && !isValidPhoneNumber(form[name]))
                  }
                  onClick={() => handleClickSave(name)}
                >
                  {t('save')}
                </button>
              </div>
            </>
          )
      }
    },
    [
      countries,
      form,
      genderOptions,
      getErrorMessage,
      handleCancel,
      handleChange,
      handleChangeGender,
      handleClickSave,
      handlePressEnter,
      handleSave,
      handleSelect,
      isLoading,
      isValidDateString,
      isValidEmail,
      isValidPhoneNumber,
      options,
      playerInfo,
    ]
  )

  const renderInformationFields = useCallback(
    (fieldsToRender: InformationField[]) => {
      if (!fieldsToRender) return null
      return (
        <div className={style.sectionContainer}>
          {fieldsToRender.map((field) => {
            return field.isActive || playerInfo.cdh_id ? (
              <FieldRenderer
                key={field?.id}
                field={field}
                activeField={activeField}
                handleClick={setActiveField}
                renderValueEdit={renderValueEdit}
                playerInfo={playerInfo}
                countries={countries}
              />
            ) : null
          })}
        </div>
      )
    },
    [activeField, countries, playerInfo, renderValueEdit]
  )

  const renderContactDetails = useCallback(
    (fieldsToRender: InformationField[]) => {
      if (!fieldsToRender) return null
      return (
        <div>
          <Text className={style.sectionHeader} textId="Contact Details" />
          <div className={style.sectionContainer}>
            {fieldsToRender.map((field) => (
              <FieldRenderer
                key={field?.id}
                field={field}
                activeField={activeField}
                handleClick={setActiveField}
                renderValueEdit={renderValueEdit}
                playerInfo={playerInfo}
                countries={countries}
              />
            ))}
          </div>
        </div>
      )
    },
    [activeField, countries, playerInfo, renderValueEdit]
  )

  const renderGuardianInformation = useCallback(
    (fieldsToRender: InformationField[]) => {
      if (!fieldsToRender) return null
      const { federation } = playerInfo

      if (federation === 'SGU') fieldsToRender[fieldsToRender.length - 2].text = 'CDH'
      if (federation === 'SGF') fieldsToRender[fieldsToRender.length - 2].text = 'Golf-ID'

      if (!federation || federation === '-')
        fieldsToRender[fieldsToRender.length - 2].text = 'words.federationNumber'
      return (
        <div>
          <Text className={style.sectionHeader} textId="Guardian Information" />
          <div className={style.sectionContainer}>
            {fieldsToRender.map((field) => (
              <FieldRenderer
                key={field?.id}
                field={field}
                activeField={activeField}
                handleClick={setActiveField}
                renderValueEdit={renderValueEdit}
                playerInfo={playerInfo}
                countries={countries}
              />
            ))}
          </div>
        </div>
      )
    },
    [activeField, countries, playerInfo, renderValueEdit]
  )

  return (
    <div className={style.container}>
      <div className={style.playerDataContainer}>
        {renderInformationFields(
          isUkUser
            ? [...switchGolfIdToCdhNumber(informationFields, isUkUser), ...sguFields]
            : informationFields
        )}
        {renderContactDetails(contactDetails)}
        {playerInfo?.age && playerInfo?.age <= 18 && renderGuardianInformation(guardianInformation)}
      </div>
      <Notes player={playerInfo} />

      <div className={style.confirmPopup}>
        <ConfirmPopup
          visible={showEmailConfirm}
          acceptTheme="default"
          rejectTextKey="cancel"
          acceptTextKey="confirm"
          title={t('players.changeEmailConfirm')}
          textKey="players.changeEmailConfirmDesc"
          titleIconSrc={WarningIcon}
          onReject={() => setEmailConfirm(false)}
          onClose={() => {
            setEmailConfirm(false)
            handleCancel()
          }}
          onAccept={() => {
            setEmailConfirm(false)
            handleSave()
          }}
        />
        <ConfirmPopup
          visible={showBDayConfirm}
          acceptTheme="default"
          rejectTextKey="cancel"
          acceptTextKey="confirm"
          title={t(
            form.birthday ? 'settings.playerCard.changeBDay' : 'settings.playerCard.addBDay'
          )}
          textKey="settings.playerCard.confirmChangeBDay"
          titleIconSrc={WarningIcon}
          onReject={() => setBDayConfirm(false)}
          onClose={() => {
            setBDayConfirm(false)
            handleCancel()
          }}
          onAccept={() => {
            setBDayConfirm(false)
            handleSave()
          }}
        />
      </div>
    </div>
  )
}

export default Information
