import { useCallback, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'

import { CLUB_QUERIES } from '@sweetspot/shared/util/constants'
import { queryBaysV2 } from '@sweetspot/sweetspot-js/features/bays/services'
import { BayScreenDisplay, BayTypeV2, TrackingTech, ViolationError } from '@sweetspot/shared/types'
import {
  createBay,
  CreateBayPayload,
  updateBay,
  UpdateBayPayload,
  updateRange,
  UpdateRangePayload,
} from '@sweetspot/shared/data-access/api-platform'
import { isInteger } from '@sweetspot/shared/util/validators'

type UseBaysProps = {
  selectedRangeId?: string
  selectedRangeToptracerSiteId?: string
}

const useBays = ({ selectedRangeId, selectedRangeToptracerSiteId }: UseBaysProps) => {
  const [selectedBays, setSelectedBays] = useState<BayTypeV2[]>([])
  const { t } = useTranslation()
  const { addToast } = useToasts()

  const {
    data,
    isLoading: areBaysLoading,
    isFetching: areBaysFetching,
    refetch,
  } = useQuery(
    [
      CLUB_QUERIES.BAYS,
      {
        drivingRangeUuid: selectedRangeId,
      },
    ],
    () =>
      queryBaysV2({
        drivingRangeId: selectedRangeId || '',
      }),
    {
      enabled: !!selectedRangeId,
    }
  )

  const checkForErrors = useCallback(
    (payload: (CreateBayPayload | UpdateBayPayload) & { siteId?: string }) => {
      const trackingProvider = payload?.bay?.ball_tracking_technology?.tracking_provider
      const display = payload?.bay?.ball_tracking_technology?.display
      const screenMode = payload?.bay?.ball_tracking_technology?.screen_mode
      if (
        trackingProvider === TrackingTech.TOPTRACER &&
        display === BayScreenDisplay.TOPTRACER_SCREEN &&
        !screenMode
      ) {
        return `${t('settings.bays.screenMode')} ${t('errors.fieldRequired').toLowerCase()}`
      }
      const trmsBayNumber = payload?.bay?.ball_tracking_technology?.api_details?.trms_bay_number
      if (trmsBayNumber && !isInteger(trmsBayNumber)) {
        return t('errors.invalidTrmsBayNumber')
      }
      return ''
    },
    [t]
  )

  const updateBayMutation = useMutation((data: UpdateBayPayload) => updateBay(data), {
    onError: (error: { error: string } | ViolationError) => {
      const errorObj = (error as ViolationError)?.violations?.[0]
      const defaultErrorMessage = errorObj
        ? `${errorObj.propertyPath}: ${errorObj.message}`
        : t('toast.defaultError')
      addToast(defaultErrorMessage, { appearance: 'error' })
    },
  })

  const createBayMutation = useMutation((data: CreateBayPayload) => createBay(data), {
    onError: (error: { error: string } | ViolationError) => {
      const errorObj = (error as ViolationError)?.violations?.[0]
      const defaultErrorMessage = errorObj
        ? `${errorObj.propertyPath}: ${errorObj.message}`
        : t('toast.defaultError')
      addToast(defaultErrorMessage, { appearance: 'error' })
    },
  })

  const updateRangeSiteIdMutation = useMutation((data: UpdateRangePayload) => updateRange(data), {
    onError: (error: { error: string } | ViolationError) => {
      const errorObj = (error as ViolationError)?.violations?.[0]
      const defaultErrorMessage = errorObj
        ? `${errorObj.propertyPath}: ${errorObj.message}`
        : t('toast.defaultError')
      addToast(defaultErrorMessage, { appearance: 'error' })
    },
  })

  const handleBayUpdate = useCallback(
    async (data: UpdateBayPayload & { siteId?: string }) => {
      const promises = [updateBayMutation.mutateAsync(data)]
      if (selectedRangeId && data.siteId !== selectedRangeToptracerSiteId) {
        promises.push(
          updateRangeSiteIdMutation.mutateAsync({
            id: selectedRangeId,
            toptracer_site_id: data.siteId,
          })
        )
      }
      const responses = await Promise.all(promises)
      return responses
    },
    [updateBayMutation, selectedRangeId, updateRangeSiteIdMutation, selectedRangeToptracerSiteId]
  )

  const handleBayCreate = useCallback(
    async (data: CreateBayPayload & { siteId?: string }) => {
      const promises = [createBayMutation.mutateAsync(data)]
      if (selectedRangeId && data.siteId !== selectedRangeToptracerSiteId) {
        promises.push(
          updateRangeSiteIdMutation.mutateAsync({
            id: selectedRangeId,
            toptracer_site_id: data.siteId,
          })
        )
      }
      const responses = await Promise.all(promises)
      return responses
    },
    [createBayMutation, selectedRangeId, updateRangeSiteIdMutation, selectedRangeToptracerSiteId]
  )

  return {
    bays: (data as { data: BayTypeV2[] })?.data || [],
    areBaysLoading,
    areBaysFetching,
    selectedBays,
    setSelectedBays,
    refetch,
    updateBay: handleBayUpdate,
    createBay: handleBayCreate,
    checkForErrors,
  }
}

export default useBays
