import { priceToLocal } from '@sweetspot/shared/util/functions'
import moment from 'moment'

export const HEADERS = {
  'player.first_name': {
    name: '.name',
    type: 'label',
    valueType: 'string',
    width: '250px',
    selected: true,
    disabled: true,
    display: (val, row) => (
      <div className="font-semibold text-sky-500">
        {row.player.first_name + ' ' + row.player.last_name}
      </div>
    ),
    isSortable: true,
  },
  'player.email': {
    name: '.email',
    type: 'label',
    width: '250px',
    selected: true,
  },
  'player.phone': {
    name: '.phone',
    type: 'label',
    valueType: 'string',
    width: '200px',
    selected: true,
    style: { fontWeight: '500' },
  },
  'membership.name': {
    name: '.words.membership',
    type: 'label',
    valueType: 'string',
    width: '200px',
    selected: true,
    style: { fontWeight: '500' },
  },
  'installment_loan.payment_date': {
    name: '.transactions.header_date',
    type: 'label',
    valueType: 'string',
    width: '200px',
    display: (val, row) => moment(val).format('L'),
    selected: true,
    isSortable: true,
  },
  'installment_loan.term': {
    name: '.transactions.header_number',
    type: 'label',
    valueType: 'string',
    width: '200px',
    selected: true,
    display: (val, row) => `${row.installment_loan.month}/${row.installment_loan.term}`,
  },
  'retry.attempts': {
    name: '.transactions.header_retries',
    type: 'label',
    valueType: 'string',
    width: '200px',
    display: (val, row) => `${row.retry.attempts}/${row.retry.max_attempts}`,
    selected: true,
  },
  payment_provider_refusal_reason: {
    name: '.membership.settings.reason',
    type: 'label',
    valueType: 'string',
    width: '200px',
    selected: true,
  },
  'amount.value': {
    name: '.words.amount',
    type: 'label',
    valueType: 'string',
    width: '200px',
    selected: true,
    style: { textAlign: 'left', fontWeight: '500' },
    display: (val, row) => priceToLocal(row.amount.value, row.amount.currency),
  },
  contract_id: {
    name: '.transactions.header_contractId',
    type: 'label',
    valueType: 'string',
    width: '300px',
    selected: true,
  },
  uuid: {
    name: '.transactions.header_id',
    type: 'label',
    valueType: 'string',
    width: '340px',
    selected: true,
  },
}
