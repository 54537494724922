import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'
import { useStyles } from '@sweetspot/sweetspot-js/common/hooks/useStyles'

const SimpleTabs = (props) => {
  const cx = useStyles(props.styles)
  return (
    <div className={cx(styles.container, props.className)} style={{ width: `${props.width}%` }}>
      {props.items.map((item) => {
        if (!item) return null
        return (
          <div
            className={cx(styles.tab, props.tabClassName, {
              [styles.active]: item.id === props.currentItem,
              [styles.disabled]: item.id !== props.currentItem && props.disabled,
            })}
            onClick={() => !props.disabled && props.onClick(item.stepNumber || item.id)}
            key={item.id}
          >
            <p
              className={cx(styles.label, props.labelClassName, {
                [props.activeLabelClassName]: item.id === props.currentItem,
                [props.inActiveLabelClassName]: item.id !== props.currentItem,
              })}
            >
              {item.label}
            </p>
            {props.errorItems.find((x) => x === item.id) ? (
              <span className={cx(styles.errorDot, styles.visible)} />
            ) : (
              <span className={cx(styles.errorDot)} />
            )}
          </div>
        )
      })}
    </div>
  )
}

SimpleTabs.propTypes = {
  items: PropTypes.array,
  currentItem: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  width: PropTypes.number,
  errorItems: PropTypes.array,
  styles: PropTypes.object,
  tabClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  activeLabelClassName: PropTypes.string,
  inActiveLabelClassName: PropTypes.string,
}

SimpleTabs.defaultProps = {
  onClick: () => {},
  width: 100,
  errorItems: [],
}

export default SimpleTabs
