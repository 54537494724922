import React, { useEffect, useMemo, useRef, useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import {
  currencyCodeInCurrentLanguage,
  selfAsync,
  to,
} from '@sweetspot/sweetspot-js/common/functions/utils'
import {
  addFeeToMembership,
  deleteFee,
  getFeeUnits,
  updateFee,
} from '@sweetspot/sweetspot-js/features/fees/services/api-platform'
import { priceToLocal } from '@sweetspot/shared/util/functions'

// import { ReactComponent as AddIcon } from '@sweetspot/club-portal-legacy/resources/images/add-sign.svg';
import { ReactComponent as TrashCan } from '@sweetspot/club-portal-legacy/resources/images/trash-can.svg'
import { ReactComponent as ClearIcon } from '@sweetspot/club-portal-legacy/resources/images/clear-icon.svg'

import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'
import produce from 'immer'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import { validateSingleFee } from '@sweetspot/club-portal-legacy/modals/MembershipWizardModal/validation'
import DropdownBox from '@sweetspot/sweetspot-js/common/components/FormElements/Partials/DropdownBox'
import ButtonBasic from '@sweetspot/sweetspot-js/common/components/ButtonBasic'
import useOnClickOutside from '@sweetspot/sweetspot-js/common/hooks/useOnClickOutside'
import { useToasts } from 'react-toast-notifications'
import _ from 'lodash'
import { useClubCurrency } from '@sweetspot/shared/util/hooks'

const PricingSettings = ({ membershipFees, membershipId, disabled, updateMembership }) => {
  const [currencyCode] = useClubCurrency()
  const { t, i18n } = useTranslation()
  const { addToast } = useToasts()

  const [showSelectFee, setShowSelectFee] = useState(false)
  const [fees, setFees] = useState([...membershipFees].sort((a, b) => b.price - a.price) || [])
  const [feeUnits, setFeeUnits] = useState([])
  const [loadingFeeUnits, setLoadingFeeUnits] = useState(true)
  const [addingFee, setAddingFee] = useState(false)
  const [feeErrors, setFeeErrors] = useState({})
  const MAX_FEE_VALUE = 999999

  const isMounted = useRef(false)

  const PriceType = {
    Monthly: 'monthly',
    Annualy: 'annualy',
  }

  const dropdownRef = useRef()
  useOnClickOutside(dropdownRef, () => {
    setShowSelectFee(false)
  })

  useEffect(() => {
    isMounted.current = true

    return () => (isMounted.current = false)
  }, [])

  useEffect(() => {
    selfAsync(async () => {
      const [res, err] = await to(getFeeUnits())

      if (isMounted.current) {
        if (!res || err) {
          // HANDLE ERROR
        } else {
          setFeeUnits(res)
        }
        setLoadingFeeUnits(false)
      }
    })
  }, [])

  const feeUnitOptions = useMemo(() => {
    return feeUnits
      .filter((unit) => !fees.find((fee) => fee.fee_unit.id === unit.id) && unit?.name)
      .map((item) => ({ ...item, label: item.name }))
  }, [feeUnits, fees])

  const saveFee = async (fee) => {
    const [validatedFee, validationErrors] = await to(validateSingleFee(fee, i18n.language))

    if (!validatedFee || validationErrors) {
      setFeeErrors(
        produce((draft) => {
          draft[fee.id] = {
            price: validationErrors?.find((x) => x.path.startsWith('price'))?.message,
            monthly_price: validationErrors?.find((x) => x.path.startsWith('monthly'))?.message,
            vat: validationErrors?.find((x) => x.path.includes('vat'))?.message,
          }
        })
      )
    } else {
      const [res, err] = await to(
        updateFee(fee.id, {
          price: validatedFee.price,
          monthly_price: validatedFee.monthly_price,
          vat: validatedFee.vat,
        })
      )
      if (!res || err) {
        // HANDLE ERROR
      } else {
        updateMembership('fees', fees)
      }
    }
  }

  const addFee = async (feeUnit) => {
    setShowSelectFee(false)
    setAddingFee(true)

    const [res, err] = await to(
      addFeeToMembership(membershipId, {
        fee_unit: feeUnit.id,
        vat: 0,
        price: 0,
        monthly_price: 0,
      })
    )

    if (err) {
      addToast(t('sentences.couldNotAddNewFee'), { appearance: 'error' })
    } else {
      const newFees = produce(fees, (draft) => {
        draft.push({
          id: res.id,
          price: res.price,
          monthly_price: res.monthly_price,
          vat: res.vat,
          fee_unit: feeUnit,
        })
      })
      updateMembership('fees', newFees)
      setFees(newFees)
    }

    setAddingFee(false)
  }

  const updateFeeVat = (feeId, value) => {
    const newValue = _.clamp(parseInt(value), 0, 100)
    setFees(
      produce((draft) => {
        const index = draft.findIndex((x) => x.id === feeId)
        draft[index].vat = newValue
      })
    )
    setFeeErrors(
      produce((draft) => {
        if (draft?.[feeId]?.vat) {
          delete draft[feeId].vat
        }
      })
    )
  }

  const updateFeePrice = (feeId, value, priceType) => {
    const maxValue = MAX_FEE_VALUE * 100
    let newValue = value * 100
    if (newValue > maxValue) {
      newValue = String(newValue).slice(0, maxValue.length)
    }
    setFees(
      produce((draft) => {
        const index = draft.findIndex((x) => x.id === feeId)
        switch (priceType) {
          case PriceType.Monthly:
            draft[index].monthly_price = newValue
            break
          case PriceType.Annualy:
            draft[index].price = newValue
            break
          default:
            // Do nothing
            break
        }
      })
    )
    setFeeErrors(
      produce((draft) => {
        if (draft?.[feeId]?.price) {
          delete draft[feeId].price
        }
      })
    )
  }

  const removeFee = async (feeId) => {
    if (fees?.length <= 1) {
      addToast(t('sentences.membershipMustHaveAtleastOneFee'), { appearance: 'error' })
      return
    }

    const beforeRemove = [...fees]

    const newFees = produce(fees, (draft) => {
      const index = draft.findIndex((x) => x.id === feeId)
      draft.splice(index, 1)
    })
    setFees(newFees)

    const [, err] = await to(deleteFee(feeId))

    if (err && isMounted.current) {
      setFees(beforeRemove)
    } else {
      updateMembership('fees', newFees)
    }
  }

  const getTotal = (priceType) => {
    let total = 0
    fees.forEach(
      (item) =>
        (total += priceType === PriceType.Annualy ? item.price ?? 0 : item.monthly_price ?? 0)
    )
    return `${priceToLocal(total, currencyCode, true)}`
  }

  return (
    <div className={cx(styles.container)}>
      <div className={cx(styles.tableContainer)}>
        {/* Header row */}
        <div className={cx(styles.headerText)}>{t('wizard.fees')}</div>
        <div className={cx(styles.headerText)}>{t('wizard.vat')}</div>
        <div className={cx(styles.headerText)}>{t('wizard.priceOnePayment')}</div>
        <div className={cx(styles.headerText)}>{t('wizard.priceMonthlyPayment')}</div>
        <div
          className={cx(styles.trashIcon, {
            [styles.trashIconDisabled]: disabled,
          })}
        >
          <TrashCan />
        </div>

        {loadingFeeUnits && (
          <div className={cx(styles.margin)}>
            <PulseLoader showIf={true} />
          </div>
        )}

        {!loadingFeeUnits && (
          <React.Fragment>
            {fees.map((fee) => {
              return (
                <div className={cx(styles.gridContent)} key={fee.fee_unit.id}>
                  <TextInputField
                    value={feeUnits.find((f) => f.id === fee.fee_unit.id)?.name || ''}
                    disabled={true}
                  />
                  <TextInputField
                    type="number"
                    value={parseInt(fee.vat)}
                    onChange={(value) => updateFeeVat(fee.id, value)}
                    onInputBlur={() => saveFee(fee)}
                    onEnter={() => saveFee(fee)}
                    inputProps={{
                      min: 0,
                      max: 100,
                    }}
                    suffix="%"
                    disabled={disabled}
                    error={feeErrors?.[fee.id]?.vat || ''}
                  />
                  <TextInputField
                    value={parseInt(fee.price) / 100}
                    type="number"
                    onInputBlur={() => saveFee(fee)}
                    onEnter={() => saveFee(fee)}
                    onChange={(value) =>
                      updateFeePrice(fee.id, value.replace(/\D/g, ''), PriceType.Annualy)
                    }
                    inputProps={{
                      min: 0,
                      max: MAX_FEE_VALUE,
                    }}
                    suffix={currencyCodeInCurrentLanguage(currencyCode)}
                    disabled={disabled}
                    error={feeErrors?.[fee.id]?.price || ''}
                  />
                  <TextInputField
                    value={fee.monthly_price ? parseInt(fee.monthly_price) / 100 : 0}
                    type="number"
                    onInputBlur={() => saveFee(fee)}
                    onEnter={() => saveFee(fee)}
                    onChange={(value) =>
                      updateFeePrice(fee.id, value.replace(/\D/g, ''), PriceType.Monthly)
                    }
                    inputProps={{
                      min: 0,
                      max: MAX_FEE_VALUE,
                    }}
                    suffix={currencyCodeInCurrentLanguage(currencyCode)}
                    disabled={disabled}
                    error={feeErrors?.[fee.id]?.price || ''}
                  />
                  <div
                    onClick={() => removeFee(fee.id)}
                    className={cx(styles.clearIcon, {
                      [styles.clearIconDisabled]: disabled,
                    })}
                  >
                    <ClearIcon />
                  </div>
                </div>
              )
            })}
            {addingFee && (
              <div>
                <PulseLoader showIf={true} />
              </div>
            )}
            <div className={cx(styles.feeButton)}>
              <ButtonBasic
                text={t('words.fee')}
                icon="plus"
                className={cx(styles.addRule)}
                onClick={() => setShowSelectFee(true)}
                disabled={disabled || addingFee || feeUnitOptions?.length <= 0}
              />
              <DropdownBox
                ref={dropdownRef}
                dropdownOpen={!disabled && showSelectFee}
                options={feeUnitOptions}
                className={styles.dropdown}
                onOptionClick={addFee}
              />
            </div>
            <div className={cx(styles.totalAnnual)}>
              {`${t('wizard.total')}`}
              <h6 className={styles.totalText}>{getTotal(PriceType.Annualy)}</h6>
            </div>
            <div className={cx(styles.totalMonthly)}>
              {`${t('wizard.total')}`}
              <h6 className={styles.totalText}>{getTotal(PriceType.Monthly)}</h6>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

PricingSettings.propTypes = {
  membershipFees: PropTypes.array,
  membershipId: PropTypes.number,
  disabled: PropTypes.bool,
  updateMembership: PropTypes.func,
}

PricingSettings.defaultProps = {}

export default PricingSettings
