import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import styles from './styles.module.scss'
import Text from '@sweetspot/club-portal-legacy/components/Text'
import {
  createAdyenAccount,
  getAccountHolder,
  getEditAccountHolderLink,
  getLegalEntity,
  getBalanceAccount,
} from '@sweetspot/sweetspot-js/features/billingSettings/services/api-platform'
import {
  ICreatAdyenAccountPayload,
  IEditAccountHolderPayload,
} from '@sweetspot/sweetspot-js/features/billingSettings/types'
import Utilities from '@sweetspot/club-portal-legacy/helpers/Utilities'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import { BalancesTable, EntityDetails, BillingAccountForm } from './components'
import { IDropdownType, IVerificationStatus } from '../../types'
import { getNextPaymentDue, getCapitilizeWord } from './functions'
import { useClubCurrency } from '@sweetspot/shared/util/hooks'
import { priceToLocal } from '@sweetspot/shared/util/functions'

export const BillingSettings = (): JSX.Element => {
  const { t, i18n } = useTranslation()
  const { addToast } = useToasts()
  const [clubCurrency] = useClubCurrency()
  const [accountType, setAccountType] = useState<string>('individual')
  const [country, setCountry] = useState<string>('SE')
  const [name, setName] = useState<string>('')
  const [nameError, setNameError] = useState<boolean>(true)
  const [email, setEmail] = useState<string>('')
  const [emailError, setEmailError] = useState<boolean>(true)
  const [accountHolder, setAccountHolder] = useState<unknown>(null)
  const [loading, setLoader] = useState<boolean>(true)
  const [totalBalance, setTotalBalance] = useState(0)
  const [submitButtonLoader, setSubmitButtonLoader] = useState(false)
  const [accType, setAccType] = useState<string>('-')
  const [entityDetails, setEntityDetails] = useState({
    email: null,
    name: null,
    address: null,
  })
  const [adyenAccountBalanceValue, setAdyenAccountBalanceValue] = useState([])
  const [verificationStatus, setVerificationStatus] = useState<IVerificationStatus>(
    IVerificationStatus.INVALID
  )

  const accountTypeValues: IDropdownType[] = [
    { id: 'individual', name: t('words.individual') },
    { id: 'business', name: t('words.business') },
  ]

  const countryValues: IDropdownType[] = [
    { id: 'SE', name: 'Sweden' },
    { id: 'DK', name: 'Denmark' },
    { id: 'GB', name: 'United Kingdom' },
    { id: 'DE', name: 'Germany' },
    { id: 'FI', name: 'Finland' },
    { id: 'NO', name: 'Norway' },
    { id: 'AT', name: 'Austria' },
    { id: 'CH', name: 'Switzerland' },
    { id: 'BE', name: 'Belgium' },
    { id: 'NL', name: 'The Netherlands' },
    { id: 'IE', name: 'Ireland' },
    { id: 'FR', name: 'France' },
    { id: 'ES', name: 'Spain' },
    { id: 'IT', name: 'Italy' },
    { id: 'LT', name: 'Lithuania' },
    { id: 'US', name: 'United States of America' },
    { id: 'CA', name: 'Canada' },
  ]

  useEffect(() => {
    getAcctHolder()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // get account related data if account exists
  useEffect(() => {
    if (accountHolder) {
      getEntityDetails()
      getBalanceAccountDetails()
      setLoader(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountHolder, i18n.language])

  // get golf club
  const golfClub = useSelector(({ golfClub }) =>
    golfClub?.list.find((club) => club?.id === golfClub?.selectedId)
  )

  const handleVerificationStatus = (status) => {
    if (status === 'valid') {
      setVerificationStatus(IVerificationStatus.VALID)
    }

    if (status === 'pending') {
      setVerificationStatus(IVerificationStatus.PENDING)
    }

    if (status === 'rejected') {
      setVerificationStatus(IVerificationStatus.REJECTED)
    }

    if (status === 'invalid') {
      setVerificationStatus(IVerificationStatus.INVALID)
    }
  }

  const getAcctHolder = async () => {
    setLoader(true)
    try {
      const { adyen_account_holder } = await getAccountHolder(golfClub.id)
      if (!adyen_account_holder) {
        setAccountHolder(null)
      } else if (Object.keys(adyen_account_holder).length) {
        setAccountHolder(adyen_account_holder)
        handleVerificationStatus(adyen_account_holder.adyen_verification_status)
      }
    } catch (error) {
      addToast(error.detail, { appearance: 'error' })
    }
    setLoader(false)
  }

  const getEntityDetails = async () => {
    setLoader(true)
    const details = {
      name: null,
      email: null,
      address: null,
    }

    try {
      const { legal_entity } = await getLegalEntity(golfClub.id)

      if (legal_entity.type === 'individual') {
        const { email, name, residentialAddress } = legal_entity.individual
        setAccType('Individual')
        details.email = email || '-'
        details.address = countryValues.find(
          (countryObject) => countryObject.id === residentialAddress.country
        )?.name
        details.name = name.firstName
      }

      if (legal_entity.type === 'organization') {
        const { email, legalName, registeredAddress } = legal_entity.organization
        setAccType('Business')
        details.email = email || '-'
        details.address = countryValues.find(
          (countryObject) => countryObject.id === registeredAddress.country
        )?.name
        details.name = legalName
      }
      setEntityDetails(details)
    } catch (error) {
      console.log(error)
    }
    setLoader(false)
  }

  const getBalanceAccountDetails = async () => {
    let totalBalance = 0
    try {
      const { balance_account } = await getBalanceAccount(golfClub.id)
      const tableReadyData = balance_account.map((ba, index) => {
        const { id, type, balances, sweep } = ba
        totalBalance = totalBalance + balances[0].balance
        if (index === balance_account.length - 1) {
          setTotalBalance(totalBalance)
        }

        if (type && sweep) {
          const { schedule_type, trigger_amount, target_amount } = sweep
          getNextPaymentDue(verificationStatus, schedule_type)
          return {
            id,
            name: getCapitilizeWord(type, true),
            payout_frequency: getCapitilizeWord(t(`words.${schedule_type}`), false),
            schedule_type,
            next_payment_due: getNextPaymentDue(verificationStatus, schedule_type),
            trigger_amount: priceToLocal(trigger_amount, clubCurrency, true),
            min_amount: priceToLocal(target_amount, clubCurrency, true),
            total_amount: priceToLocal(balances[0].balance, clubCurrency, true),
          }
        }

        if (type && !sweep) {
          return {
            id,
            name: getCapitilizeWord(type, true),
            next_payment_due: getNextPaymentDue(verificationStatus, null),
            total_amount: priceToLocal(balances[0].balance, clubCurrency, true),
          }
        }

        return {}
      })
      setAdyenAccountBalanceValue(tableReadyData)
    } catch (error) {
      console.log(error)
    }
  }

  const handleNameField = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 3) {
      setName(event.target.value)
      setNameError(false)
    } else {
      setNameError(true)
    }
  }

  const handleEmailFiled = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length) {
      const isValidEmail = Utilities.validateEmail(event.target.value)
      if (isValidEmail) {
        setEmail(event.target.value)
        setEmailError(false)
      } else {
        setEmailError(true)
      }
    }
  }

  const handleSubmitBtn = async () => {
    setSubmitButtonLoader(true)
    const domainName = window.origin
    const payload: ICreatAdyenAccountPayload = {
      type: 'individual',
      legal_name: '',
      first_name: name,
      last_name: 'lastname',
      email,
      country,
      redirect_url: `${domainName}/settings/billing-settings`,
    }

    if (accountType === 'business') {
      payload.type = 'organization'
      payload.legal_name = name
      payload.first_name = ''
    }

    try {
      const response = await createAdyenAccount({ clubId: golfClub.id, payload })
      if (response) {
        window.open(response.onboardingUrl, '_self')
        getAcctHolder()
        setSubmitButtonLoader(false)
      }
    } catch (error) {
      setSubmitButtonLoader(false)
      addToast(t('errors.somethingWentWrongNoId'), { appearance: 'error' })
    }
  }

  const handleEditAccountBtn = async () => {
    const domainName = window.origin
    const payload: IEditAccountHolderPayload = {
      redirect_url: `${domainName}/settings/billing-settings`,
    }

    try {
      const response = await getEditAccountHolderLink({ clubId: golfClub.id, payload })
      if (response) {
        window.open(response.onboardingUrl, '_blank')
      }
    } catch (error) {
      addToast(error.detail, { appearance: 'error' })
    }
  }

  const renderContent = () => {
    if (loading) {
      return (
        <div className={styles.contentContainer}>
          <PulseLoader fillHeight showIf={loading} />
        </div>
      )
    } else if (accountHolder) {
      // display loader if there is account holder but no details related to it [entity details]
      if (!entityDetails.name) {
        return (
          <div className={styles.contentContainer}>
            <PulseLoader fillHeight showIf={loading} />
          </div>
        )
      } else {
        return (
          <>
            <EntityDetails
              verificationStatus={verificationStatus}
              entityDetails={entityDetails}
              totalBalance={totalBalance}
              accType={accType}
            />
            {adyenAccountBalanceValue.length ? (
              <BalancesTable
                clubId={golfClub.id}
                getBalanceAccountDetails={getBalanceAccountDetails}
                adyenAccountBalanceValue={adyenAccountBalanceValue}
              />
            ) : (
              ''
            )}
          </>
        )
      }
    } else {
      return (
        <BillingAccountForm
          accountType={accountType}
          accountTypeValues={accountTypeValues}
          setAccountType={setAccountType}
          country={country}
          countryValues={countryValues}
          setCountry={setCountry}
          handleEmailFiled={handleEmailFiled}
          handleNameField={handleNameField}
          emailError={emailError}
          nameError={nameError}
          handleSubmitBtn={handleSubmitBtn}
          submitButtonLoader={submitButtonLoader}
        />
      )
    }
  }

  const renderEditButton = () => {
    if (!loading) {
      if (accountHolder) {
        return (
          <button className="system-button info-outline md-32" onClick={handleEditAccountBtn}>
            {t('sentences.editAccount')}
          </button>
        )
      }
    }

    return null
  }
  return (
    <div className={cx(styles.container)}>
      <div className={styles.title}>
        <Text textId="Billing settings" />
        {renderEditButton()}
      </div>
      {renderContent()}
    </div>
  )
}
