export const RULE_TYPES = [
  {
    name: 'booking-source',
    id: 1,
    label: 'sentences.bookingSource',
    value: 'source_checker',
    context: ['club', 'membership'],
  },
  {
    name: 'venue-checker',
    id: 2,
    label: 'sentences.coursesAndSpaces',
    value: 'venue_checker',
    context: ['club', 'membership'],
  },
  {
    name: 'days-and-times-in-week',
    id: 3,
    label: 'sentences.daysAndTimes',
    value: 'days_and_times_in_week_checker',
    context: ['club', 'membership'],
  },
  {
    name: 'prime-time-checker',
    id: 4,
    label: 'sentences.primeTime',
    value: 'prime_time_checker',
    context: ['club', 'membership'],
  },
  {
    name: 'booking-period-checker',
    id: 5,
    label: 'bookingPeriod.title',
    value: 'booking_period_checker',
    context: ['club', 'membership'],
    hint: 'bookingPeriod.hint_Title',
  },
  {
    name: 'rolling-booking-window-checker',
    id: 6,
    label: 'teeTimeCategory.bookingWindow',
    value: 'rolling_booking_window_checker',
    context: ['club', 'membership'],
  },
]

export const TYPES_OBJ = {
  SOURCE: 'source_checker',
  COURSESANDSPACES: 'venue_checker',
  DAYSANDTIMES: 'days_and_times_in_week_checker',
  PRIMETIME: 'prime_time_checker',
  PERIOD: 'booking_period_checker',
  BOOKING_WINDOW: 'rolling_booking_window_checker',
}

export const BOOKING_SOURCE_OPTIONS = [
  {
    id: 1,
    value: 'club_portal',
    label: 'sentences.clubPortal',
  },
  {
    id: 2,
    value: 'mobile_app',
    label: 'sentences.mobileApp',
  },
  {
    id: 3,
    value: 'web_booking',
    label: 'sentences.webBooking',
  },
]

export const DAYS_OPTIONS = [
  {
    value: 'Monday',
    id: 1,
    label: 'dateTime.longDays.monday',
  },
  {
    value: 'Tuesday',
    id: 2,
    label: 'dateTime.longDays.tuesday',
  },
  {
    value: 'Wednesday',
    id: 3,
    label: 'dateTime.longDays.wednesday',
  },
  {
    value: 'Thursday',
    id: 4,
    label: 'dateTime.longDays.thursday',
  },
  {
    value: 'Friday',
    id: 5,
    label: 'dateTime.longDays.friday',
  },
  {
    value: 'Saturday',
    id: 6,
    label: 'dateTime.longDays.saturday',
  },
  {
    value: 'Sunday',
    id: 7,
    label: 'dateTime.longDays.sunday',
  },
]
