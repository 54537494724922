export const HEADERS = {
  name: {
    name: '.words.name',
    type: 'label',
    width: '250px',
    selected: true,
    disabled: true,
    isSortable: false,
  },
  email: {
    name: '.words.email',
    type: 'label',
    width: '250px',
    selected: true,
    isSortable: false,
  },
  birthDate: {
    name: '.settings.playerCard.birthDate',
    type: 'label',
    valueType: 'string',
    width: '200px',
    selected: true,
  },
  membershipName: {
    name: '.words.membership',
    type: 'label',
    valueType: 'string',
    width: '200px',
    selected: true,
  },
  signUpDate: {
    name: '.membership.options.signupDate',
    type: 'label',
    valueType: 'string',
    width: '200px',
    selected: true,
  },
  gitClub: {
    name: '.settings.playerCard.gitClub',
    type: 'label',
    valueType: 'string',
    width: '200px',
    selected: true,
  },
  isDone: {
    name: '.words.done',
    type: 'checkbox',
    selected: true,
    valueType: 'boolean',
  },
}
